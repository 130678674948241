import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';

import { Link } from 'react-router-dom';

const styles = () => ({
  primaryText: {
    fontSize: '1.25em'
  },
  secondayText: {
    fontSize: '1em'
  },
  listItem: {
    textDecoration: 'none',
    color: 'black'
  }
});

class WordListItem extends React.Component {
  handleOnClick = () => {
    const { word, handleWordClicked } = this.props;
    handleWordClicked(word);
  };

  render() {
    const { word, classes } = this.props;
    return (
      <Link to={`/words/${word.id}`} className={classes.listItem}>
        <ListItem button>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.primaryText,
              secondary: classes.secondayText
            }}
            primary={word.translationLit}
            secondary={word.english}
          />
        </ListItem>
        <Divider />
      </Link>
    );
  }
}

WordListItem.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WordListItem);
