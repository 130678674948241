import React from 'react';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import configureStore from './store/configureStore';
import logo from './logo.svg';
import AppRouter from './routers/AppRouter';
import { firebase } from './firebase/firebase';
import { startSetWords } from './actions/wordActions';
import { loginAction, logoutAction } from './actions/auth';
// import { startSetWords, setWords } from './actions/wordActions';
// import setPageWordIds from './actions/pageActions';
// import daysBetween from './misc/daysBetween';

ReactGA.initialize('UA-96005890-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export const store = configureStore();
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#fff',
      main: 'rgb(253, 197, 2)',
      dark: 'rgb(184, 143, 23)'
    },
    secondary: {
      main: '#f44336'
    }
  },
  typography: {
    useNextVariants: true
  }
});

// // For production onlyy
// const wordData = JSON.parse(localStorage.getItem('learning-esan-words'));

// if (wordData) {
//   const date = new Date();
//   if (daysBetween(wordData.timestamp, date.getTime()) > 5) {
//     store.dispatch(startSetWords()).then(() => {
//       return store.dispatch(setPageWordIds());
//     });
//   } else {
//     store.dispatch(setWords(wordData.data));
//   }
// } else {
//   store.dispatch(startSetWords()).then(() => {
//     return store.dispatch(setPageWordIds());
//   });
// }

store.dispatch(startSetWords());

firebase.auth().onAuthStateChanged(user => {
  if (user) {
    if (user.email !== 'abumere@gmail.com') {
      user.delete();
      store.dispatch(logoutAction());
    } else {
      store.dispatch(loginAction(user.uid));
    }
  } else {
    store.dispatch(logoutAction());
  }
});

const App = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <AppRouter logo={logo} />
    </Provider>
  </MuiThemeProvider>
);

export default App;
