// words REDUCER
// FOR DEV PURPOSES ONLY
// import wordsDefaultState from '../tests/fixtures/wordsLong';
const wordsDefaultState = [];

export default (state = wordsDefaultState, action) => {
  switch (action.type) {
    case 'ADD_WORD':
      return [...state, action.word];
    case 'REMOVE_WORD':
      return state.filter(word => word.id !== action.id);
    case 'EDIT_WORD':
      return state.map(word => {
        if (word.id === action.id) {
          return {
            ...word,
            ...action.updates
          };
        }
        return word;
      });
    case 'SET_WORDS':
      return action.words;
    default:
      return state;
  }
};
