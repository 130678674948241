import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AlphabetIcon from '@material-ui/icons/SortByAlpha';
import GroupIcon from '@material-ui/icons/Group';
import WarningIcon from '@material-ui/icons/Warning';
import DashboardWidget from '../components/DashboardWidget';
import AddWordPage from './Words/AddWordPage';
import db from '../firebase/firebase';
import DataTable from '../components/DataTable/DataTable';
import { startRemoveWord } from '../actions/wordActions';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
});

export class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    db.collection('stats')
      .get()
      .then(querySnap => {
        querySnap.forEach(doc => {
          this.setState(prevState => {
            const obj = {
              ...prevState
            };
            obj[doc.id] = doc.data().value;
            return obj;
          });
        });
      });
  }

  handleDeleteWord = word => {
    const { startRemoveWordAction } = this.props;
    return startRemoveWordAction(word);
  };

  render() {
    const { classes, words } = this.props;
    const { totalUsers } = this.state;
    let incompleteWords = 0;
    const totalWords = words.length;

    words.forEach(word => {
      if (!word.image || !word.audio) {
        incompleteWords += 1;
      }
    });

    const tableOptions = {
      onRowClick: rowData => {
        const { history } = this.props;
        history.push(`/dashboard/word/edit/${rowData[0]}`);
      }
    };

    return (
      <div className={classes.root}>
        <Grid container spacing={8}>
          <Grid item xs={4}>
            <DashboardWidget
              title="Words"
              icon={<AlphabetIcon />}
              iconColor="green"
            >
              {totalWords}
            </DashboardWidget>
          </Grid>
          <Grid item xs={4}>
            <DashboardWidget
              title="Users"
              icon={<GroupIcon />}
              iconColor="blue"
            >
              {totalUsers}
            </DashboardWidget>
          </Grid>
          <Grid item xs={4}>
            <DashboardWidget
              title="Incomplete Words"
              icon={<WarningIcon />}
              iconColor="red"
            >
              {incompleteWords}
            </DashboardWidget>
          </Grid>
          <Grid item xs={3}>
            <AddWordPage />
          </Grid>
          <Grid item xs={9}>
            <DataTable
              title="Word List"
              wordData={words}
              tableOptions={tableOptions}
              handleDeleteRow={this.handleDeleteWord || {}}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

DashboardPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    words: state.words
  };
};
const mapDispatchToProps = dispatch => {
  return {
    startRemoveWordAction: word => dispatch(startRemoveWord(word))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DashboardPage));
