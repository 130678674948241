import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import WordForm from '../../components/Words/WordForm';
import { startAddWord } from '../../actions/wordActions';

const styles = {
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    letterSpacing: '.06em',
    textAlign: 'center'
  },
  '@media screen and (min-width: 20rem)': {
    title: {
      fontSize: '0.7em'
    }
  },
  '@media screen and (min-width: 24rem)': {
    title: {
      fontSize: '0.8em'
    }
  },
  '@media screen and (min-width: 30rem)': {
    title: {
      fontSize: '1em'
    }
  }
};

class AddWordPage extends React.Component {
  saveWord = word => {
    const { startAddWordAction } = this.props;

    const newWord = {
      translation: word.translation.toLowerCase().trim(),
      translationLit: word.translationLit.toLowerCase().trim(),
      english: word.english.toLowerCase().trim(),
      image: word.image,
      imageName: word.imageName,
      audio: word.audio,
      audioName: word.audioName
    };

    return startAddWordAction(newWord);
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.title}>
        <WordForm title="Add Word" handleWordSubmit={this.saveWord} word={{}} />
      </div>
    );
  }
}

AddWordPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    words: state.words
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startAddWordAction: word => dispatch(startAddWord(word))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddWordPage));
