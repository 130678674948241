import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import WordDisplay from '../../components/Words/WordDisplay';
import WordDisplayButton from '../../components/Buttons/WordDisplayButton';
import { startSetBodyIds } from '../../actions/bodyWordActions';
import PageHead from '../../components/PageHead';

const styles = {
  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '1em'
  },
  itemsBody: {
    marginTop: '10px',
    textAlign: 'center'
  }
};

export class BodyPartsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedWord: {
        word: undefined
      }
    };
  }

  componentDidMount() {
    const { startSetBodyIdsAction } = this.props;
    startSetBodyIdsAction().then(() => {
      const { wordIds } = this.props;
      this.handleButtonPressed(wordIds[0]);
    });
  }

  handleButtonPressed = id => {
    const { words } = this.props;
    const word = words.filter(wordItem => wordItem.id === id).pop();
    const selectedWord = {
      word
    };

    this.setState(() => ({
      selectedWord
    }));
  };

  render() {
    const { classes, wordIds } = this.props;
    const { selectedWord } = this.state;

    return (
      <div>
        <PageHead
          title="Vocabulary - Body Parts"
          description="Learn The Parts of The Body In Esan"
          keywords="esan,ishan,edo,nigeria,learn,body,parts,body parts,vocabulary,language"
        />
        <div className={classes.body}>
          <h1>Parts of The Body</h1>
          {!!selectedWord && !!selectedWord.word && (
            <div className={classes.body}>
              <WordDisplay word={selectedWord.word} />
            </div>
          )}
          <div className={classes.itemsBody}>
            <WordDisplayButton
              wordId={wordIds[0]}
              onButtonClicked={this.handleButtonPressed}
            >
              Head
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[1]}
              onButtonClicked={this.handleButtonPressed}
            >
              Eye
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[2]}
              onButtonClicked={this.handleButtonPressed}
            >
              Nose
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[3]}
              onButtonClicked={this.handleButtonPressed}
            >
              Ear
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[4]}
              onButtonClicked={this.handleButtonPressed}
            >
              Mouth
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[5]}
              onButtonClicked={this.handleButtonPressed}
            >
              Teeth
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[6]}
              onButtonClicked={this.handleButtonPressed}
            >
              Neck
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[7]}
              onButtonClicked={this.handleButtonPressed}
            >
              Shoulder
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[8]}
              onButtonClicked={this.handleButtonPressed}
            >
              Hair
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[9]}
              onButtonClicked={this.handleButtonPressed}
            >
              Hand
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[10]}
              onButtonClicked={this.handleButtonPressed}
            >
              Fingers
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[11]}
              onButtonClicked={this.handleButtonPressed}
            >
              Stomach
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[12]}
              onButtonClicked={this.handleButtonPressed}
            >
              Belly Button
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[13]}
              onButtonClicked={this.handleButtonPressed}
            >
              Leg
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[14]}
              onButtonClicked={this.handleButtonPressed}
            >
              Toes
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[15]}
              onButtonClicked={this.handleButtonPressed}
            >
              Knee
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[16]}
              onButtonClicked={this.handleButtonPressed}
            >
              Waist
            </WordDisplayButton>
          </div>
        </div>
      </div>
    );
  }
}

BodyPartsPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const vals = [];
  state.vocabBodyPartsIds.forEach(id => {
    let foundWord = state.words.filter(word => word.id === id).pop();
    foundWord = foundWord || {};
    vals.push(foundWord);
  });

  return {
    words: vals,
    wordIds: state.vocabBodyPartsIds
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startSetBodyIdsAction: () => dispatch(startSetBodyIds())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BodyPartsPage));
