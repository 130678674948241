import React from 'react';
import { Helmet } from 'react-helmet';

const PageHead = props => {
  const { title, keywords, description } = props;

  return (
    <Helmet>
      <title>{`Learning Esan | ${title}`}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

export default PageHead;
