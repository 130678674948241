import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PageHead from '../components/PageHead';
import WordDisplay from '../components/Words/WordDisplay';
import WordDisplayButton from '../components/Buttons/WordDisplayButton';
import { startSetCountingIds } from '../actions/countingWordActions';

const styles = {
  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  header: {
    textAlign: 'center'
  },
  '@media screen and (min-width: 25rem)': {},
  '@media screen and (min-width: 30rem)': {}
};

const numbers = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20'
];
const numbers2 = [
  { number: 10, index: 9 },
  { number: 20, index: 19 },
  { number: 30, index: 20 },
  { number: 40, index: 21 },
  { number: 50, index: 22 },
  { number: 60, index: 23 },
  { number: 70, index: 24 },
  { number: 80, index: 25 },
  { number: 90, index: 26 },
  { number: 100, index: 27 }
];

class CountingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedWord: {
        word: undefined
      }
    };
  }

  componentDidMount() {
    const { startSetCountingIdsAction } = this.props;
    startSetCountingIdsAction().then(() => {
      const { wordIds } = this.props;
      this.handleButtonPressed(wordIds[0]);
    });
  }

  handleButtonPressed = (id, letter) => {
    const { words } = this.props;
    const word = words.filter(wordItem => wordItem.id === id).pop();
    const selectedWord = {
      word,
      letter
    };

    this.setState(() => ({
      selectedWord
    }));
  };

  render() {
    const { classes, wordIds } = this.props;
    const { selectedWord } = this.state;

    return (
      <div>
        <PageHead
          title="Counting and Numbers In Esan"
          description="Learn counting and numbers in Esan"
          keywords="esan,words,esan words,ishan,edo,nigeria,learn,counting,numbers,language"
        />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h1 className={classes.header}>Numbers: 1 - 100</h1>
          </Grid>
          <Grid item xs={12} className={classes.body}>
            {!!selectedWord.word && <WordDisplay word={selectedWord.word} />}
          </Grid>
          {numbers.map((item, index) => (
            <Grid key={item} item xs={2} sm={1}>
              <WordDisplayButton
                wordId={wordIds[index]}
                onButtonClicked={this.handleButtonPressed}
              >
                {item}
              </WordDisplayButton>
            </Grid>
          ))}
          <Grid item xs={12} />
          <Grid item xs={12}>
            <h1 className={classes.header}>Counting By {"10's"}</h1>
          </Grid>
          <Grid container spacing={0} className={classes.body}>
            {numbers2.map(item => (
              <Grid key={item.number} item xs={2} sm={1}>
                <WordDisplayButton
                  wordId={wordIds[item.index]}
                  onButtonClicked={this.handleButtonPressed}
                >
                  {item.number}
                </WordDisplayButton>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
    );
  }
}

CountingPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const vals = [];
  state.countingIds.forEach(id => {
    let foundWord = state.words.filter(word => word.id === id).pop();
    foundWord = foundWord || {};
    vals.push(foundWord);
  });

  return {
    words: vals,
    wordIds: state.countingIds
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startSetCountingIdsAction: () => dispatch(startSetCountingIds())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CountingPage));
