import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class DialogBox extends React.Component {
  handleClose = () => {
    const { close } = this.props;
    close();
  };

  handlePickOption1 = () => {
    const { option1Select, close } = this.props;
    option1Select();
    close();
  };

  handlePickOption2 = () => {
    const { option2Select, close } = this.props;
    option2Select();
    close();
  };

  render() {
    const { open, option1, option2, title, children } = this.props;

    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handlePickOption1}
          >
            {option1}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={this.handlePickOption2}
            autoFocus
          >
            {option2}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DialogBox;
