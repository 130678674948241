import moment from 'moment';
import db, { storage } from '../firebase/firebase';
import { fileUploadHelper, fileDeleter } from '../firebase/utilities';

// ADD_WORD
export const addWord = word => ({
  type: 'ADD_WORD',
  word
});

export const startAddWord = (wordData = {}) => {
  // Thunk needs to accept a function which fetches data and then dispatches action
  return dispatch => {
    const {
      translation = '',
      translationLit = '',
      english = '',
      image = '',
      imageName = '',
      audio = '',
      audioName = ''
    } = wordData;

    const word = {
      translation,
      translationLit,
      english,
      image,
      imageName,
      audio,
      audioName,
      createdAt: moment().format()
    };

    if (translation === '' || translationLit === '' || english === '') {
      return null;
    }

    return fileUploadHelper(
      word,
      image,
      'image',
      process.env.REACT_APP_CDN_BASE_IMAGE_DIR
    )
      .then(resolvedWord => {
        return fileUploadHelper(
          resolvedWord,
          audio,
          'audio',
          process.env.REACT_APP_CDN_BASE_AUDIO_DIR
        );
      })
      .then(resolvedWord => {
        return db
          .collection(process.env.REACT_APP_BASE_WORD_COLLECTION)
          .add(resolvedWord)
          .then(docRef => {
            dispatch(
              addWord({
                id: docRef.id,
                ...resolvedWord
              })
            );
          });
      })
      .then(() => {
        const docRef = db.collection('stats').doc('totalWords');
        docRef.get().then(doc => {
          if (doc.exists) {
            docRef.set({
              value: doc.data().value + 1
            });
          }
        });
      })
      .catch(error => {
        console.error('Error adding word document: ', error);
      });
  };
};

// REMOVE_WORD
export const removeWord = ({ id } = {}) => ({
  type: 'REMOVE_WORD',
  id
});

export const startRemoveWord = (wordData = {}) => {
  return dispatch => {
    const { id = '', imageName = '', audioName = '' } = wordData;

    return db
      .collection(process.env.REACT_APP_BASE_WORD_COLLECTION)
      .doc(id)
      .delete()
      .then(() => {
        if (imageName !== '') {
          storage
            .ref(process.env.REACT_APP_CDN_BASE_IMAGE_DIR)
            .child(imageName)
            .delete()
            .then(() => {
              console.log('Image deleted');
            });
        }

        if (audioName !== '') {
          storage
            .ref(process.env.REACT_APP_CDN_BASE_AUDIO_DIR)
            .child(audioName)
            .delete()
            .then(() => {
              console.log('Audio delete');
            });
        }

        dispatch(removeWord(wordData));
        return Promise.resolve(wordData);
      })
      .then(() => {
        const docRef = db.collection('stats').doc('totalWords');
        docRef.get().then(doc => {
          if (doc.exists) {
            docRef.set({
              value: doc.data().value - 1
            });
          }
        });
      });
  };
};

// EDIT_WORD
export const editWord = (id, updates) => ({
  type: 'EDIT_WORD',
  id,
  updates
});

export const startEditWord = (wordData = {}, id = '') => {
  return dispatch => {
    const {
      translation = '',
      translationLit = '',
      english = '',
      image = '',
      imageName = '',
      audio = '',
      audioName = '',
      createdAt
    } = wordData;

    const word = {
      translation,
      translationLit,
      english,
      image,
      imageName,
      audio,
      audioName,
      createdAt:
        typeof createdAt === 'string' || createdAt instanceof String
          ? createdAt
          : createdAt.format()
    };

    const docRef = db
      .collection(process.env.REACT_APP_BASE_WORD_COLLECTION)
      .doc(wordData.id);
    return docRef
      .get()
      .then(doc => {
        return fileDeleter(doc.data(), word, 'image').then(resolvedWord => {
          return fileDeleter(doc.data(), resolvedWord, 'audio');
        });
      })
      .then(resolvedWord => {
        console.log('Doing file document write', resolvedWord);
        return docRef
          .set(resolvedWord)
          .then(() => Promise.resolve({ ...resolvedWord, id: docRef.id }));
      })
      .then(resolvedWord => {
        console.log('Word has been updated ', resolvedWord);
        const newWord = {
          ...resolvedWord,
          createdAt: moment(resolvedWord.createdAt)
        };
        dispatch(editWord(id, newWord));
        return Promise.resolve(newWord);
      })
      .catch(error => {
        console.error('Error editing word document: ', error);
      });
  };
};

export const setWords = words => ({
  type: 'SET_WORDS',
  words
});

export const startSetWords = () => {
  return dispatch => {
    return db
      .collection(process.env.REACT_APP_BASE_WORD_COLLECTION)
      .get()
      .then(querySnapshot => {
        const data = [];

        querySnapshot.forEach(doc => {
          data.push({
            id: doc.id,
            translation: doc.data().translation,
            translationLit: doc.data().translationLit,
            english: doc.data().english,
            audio: doc.data().audio,
            audioName: doc.data().audioName,
            image: doc.data().image,
            imageName: doc.data().imageName,
            pos: doc.data().pos,
            createdAt: doc.data().createdAt
              ? moment(doc.data().createdAt)
              : moment()
          });
        });

        dispatch(setWords(data));
        const wordData = JSON.stringify({
          data,
          timestamp: new Date().getTime()
        });
        localStorage.setItem('learning-esan-words', wordData);
      })
      .catch(error => {
        console.error('Error fetching word documents: ', error);
      });
  };
};
