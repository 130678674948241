import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import purple from '@material-ui/core/colors/purple';
import orange from '@material-ui/core/colors/deepOrange';
import green from '@material-ui/core/colors/teal';
import lime from '@material-ui/core/colors/lime';
import pink from '@material-ui/core/colors/pink';
import { Link } from 'react-router-dom';
import PageHead from '../components/PageHead';
import { startSetIntonationIds } from '../actions/intonationWordActions';

const styles = theme => ({
  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '1em'
  },
  header: {
    textAlign: 'center'
  },
  table: {
    width: '100%',
    margin: '0 auto'
  },
  tableTitle: {
    color: '#929292',
    fontStyle: 'italic',
    textAlign: 'left'
  },
  headerTable: {
    margin: '0 auto'
  },
  headerTitle: {
    color: '#929292',
    fontStyle: 'italic'
  },
  text: {
    margin: '0px 10px 10px 10px'
  },
  bottomBorder: {
    borderBottom: '2px solid black'
  },
  highlightRed: {
    fontWeight: 'bold',
    color: red[500]
  },
  highlightBlue: {
    fontWeight: 'bold',
    color: blue[500]
  },
  highlightPurple: {
    fontWeight: 'bold',
    color: purple[500]
  },
  highlightOrange: {
    fontWeight: 'bold',
    color: orange[500]
  },
  highlightGreen: {
    fontWeight: 'bold',
    color: green[500]
  },
  highlightLime: {
    fontWeight: 'bold',
    color: lime[900]
  },
  highlightPink: {
    fontWeight: 'bold',
    color: pink[500]
  },
  highlightTheme: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark
  },
  '@media screen and (min-width: 20rem)': {
    tableEx: {
      fontSize: '0.6em'
    },
    headerEx: {
      fontSize: '0.8em'
    }
  },
  '@media screen and (min-width: 25rem)': {
    text: {
      fontSize: '1.1em'
    },
    tableEx: {
      fontSize: '0.7em'
    },
    headerEx: {
      fontSize: '1.1em'
    },
    table: {
      borderSpacing: '0px 0px'
    },
    headerTable: {
      borderSpacing: '10px 3px'
    }
  },
  '@media screen and (min-width: 45rem)': {
    text: {
      fontSize: '1.3em'
    },
    tableEx: {
      fontSize: '1.4em'
    },
    headerEx: {
      fontSize: '1.5em'
    },
    table: {
      borderSpacing: '0px 0px'
    },
    headerTable: {
      borderSpacing: '25px 3px'
    }
  }
});

class PronounsPage extends React.Component {
  componentDidMount() {
    const { startSetIntonationIdsAction } = this.props;
    startSetIntonationIdsAction();
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <PageHead
          title="Learn Esan Pronouns"
          description="Learn about the types of pronouns in Esan. Pronouns are an important part of learning any language. There are 7 types of pronouns in Esan. Here are some example sentences with different types of pronouns."
          keywords="esan,ishan,edo,nigeria,learn,pronouns"
        />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h1 className={classes.header}>Pronouns</h1>
          </Grid>
          <Grid item xs={12}>
            <table className={classes.headerTable}>
              <tbody>
                <tr className={classes.headerTitle}>
                  <th>English</th>
                  <th>Written</th>
                  <th>Spoken</th>
                </tr>
                <tr className={classes.headerEx}>
                  <td>
                    <span className={classes.highlightBlue}>I</span> see him
                  </td>
                  <td>
                    <span className={classes.highlightBlue}>Mẹn</span> da ghe
                    ọle
                  </td>
                  <td>
                    <span className={classes.highlightBlue}>Mẹn</span> da ghọle
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.text}>
              <p>
                <span className={classes.highlightTheme}>P</span>ronouns are an
                important building block of any language. In Esan there are 7
                types of pronouns: Focus, Object, Reflexive, General Possessor,
                Personal Possessor, and Independant Possessor.
              </p>
              <p>
                <span className={classes.highlightTheme}>I</span>n this section
                here are a few important things to keep in mind. First, in Esan
                there is no difference when describing (him,her, and it) when it
                comes to pronouns. Second, there are two types of possessor
                pronouns. When describing something specific to you a possessor
                personal pronoun is used. In all other cases the general
                possessor pronoun is used.
              </p>
              <p>
                Below are some examples of different forms of Esan pronouns.
                NOTE: The right column shows Esan in its{' '}
                <strong>
                  <em>written form</em>
                </strong>
                . When{' '}
                <strong>
                  <em>speaking</em>
                </strong>{' '}
                these pharases, you must take into account{' '}
                <Link to="/learn/elision">vowel elision</Link>.
              </p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <h2 className={classes.header}>Pronoun Examples</h2>
            {/** Example 1 */}
            <table className={classes.table}>
              <thead>
                <tr className={classes.tableTitle}>
                  <th className={classes.bottomBorder}>Type</th>
                  <th className={classes.bottomBorder}>Pronoun</th>
                  <th className={classes.bottomBorder}>English</th>
                  <th className={classes.bottomBorder}>Written</th>
                </tr>
              </thead>
              <tbody className={classes.tableEx}>
                <tr>
                  <td>Focus</td>
                  <td>I</td>
                  <td>
                    It’s <span className={classes.highlightBlue}>me</span>
                  </td>
                  <td>
                    <span className={classes.highlightBlue}>Imẹn</span> nọ
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>You</td>
                  <td>
                    It’s <span className={classes.highlightBlue}>you</span>
                  </td>
                  <td>
                    <span className={classes.highlightBlue}>Uwẹ</span> nọ
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>He/She/It</td>
                  <td>
                    It’s{' '}
                    <span className={classes.highlightBlue}>him/her/it</span>
                  </td>
                  <td>
                    <span className={classes.highlightBlue}>Ọle</span> nọ
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>We</td>
                  <td>
                    It’s <span className={classes.highlightBlue}>us</span>
                  </td>
                  <td>
                    <span className={classes.highlightBlue}>Iman</span> nọ
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>You (all)</td>
                  <td>
                    It’s{' '}
                    <span className={classes.highlightBlue}>you (all)</span>
                  </td>
                  <td>
                    <span className={classes.highlightBlue}>Ibha</span> nọ
                  </td>
                </tr>
                <tr>
                  <td className={classes.bottomBorder} />
                  <td className={classes.bottomBorder}>They</td>
                  <td className={classes.bottomBorder}>
                    It’s <span className={classes.highlightBlue}>them</span>
                  </td>
                  <td className={classes.bottomBorder}>
                    <span className={classes.highlightBlue}>Ele</span> nọ
                  </td>
                </tr>
                {/** Row 2 */}
                <tr>
                  <td>Subject</td>
                  <td>I</td>
                  <td>
                    <span className={classes.highlightRed}>I</span> see him
                  </td>
                  <td>
                    <span className={classes.highlightRed}>Mẹn</span> da ghe ọle
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>You</td>
                  <td>
                    <span className={classes.highlightRed}>You</span> see him
                  </td>
                  <td>
                    <span className={classes.highlightRed}>Wẹ</span> da ghe ọle
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>He/She/It</td>
                  <td>
                    <span className={classes.highlightRed}>He/She/It</span> sees
                    him
                  </td>
                  <td>
                    <span className={classes.highlightRed}>Ọ</span> da ghe ọle
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>We</td>
                  <td>
                    <span className={classes.highlightRed}>We</span> see him
                  </td>
                  <td>
                    <span className={classes.highlightRed}>Mhan</span> da ghe
                    ọle
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>You (all)</td>
                  <td>
                    <span className={classes.highlightRed}>You (Y'all)</span>{' '}
                    see him
                  </td>
                  <td>
                    <span className={classes.highlightRed}>Bha</span> da ghe ọle
                  </td>
                </tr>
                <tr>
                  <td className={classes.bottomBorder} />
                  <td className={classes.bottomBorder}>They</td>
                  <td className={classes.bottomBorder}>
                    <span className={classes.highlightRed}>They</span> see him
                  </td>
                  <td className={classes.bottomBorder}>
                    <span className={classes.highlightRed}>E</span> da ghe ọle
                  </td>
                </tr>
                {/** Row 3 */}
                <tr>
                  <td>Object</td>
                  <td>I</td>
                  <td>
                    He sees <span className={classes.highlightPurple}>me</span>
                  </td>
                  <td>
                    Ọ da ghe{' '}
                    <span className={classes.highlightPurple}>mhẹn</span>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>You</td>
                  <td>
                    He sees <span className={classes.highlightPurple}>you</span>
                  </td>
                  <td>
                    Ọ da ghe <span className={classes.highlightPurple}>ẹ</span>{' '}
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>He/She/It</td>
                  <td>
                    He sees{' '}
                    <span className={classes.highlightPurple}>him/her/it</span>
                  </td>
                  <td>
                    Ọ da ghe{' '}
                    <span className={classes.highlightPurple}>ọle</span>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>We</td>
                  <td>
                    He sees <span className={classes.highlightPurple}>us</span>
                  </td>
                  <td>
                    Ọ da ghe{' '}
                    <span className={classes.highlightPurple}>mhan</span>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>You (all)</td>
                  <td>
                    He sees{' '}
                    <span className={classes.highlightPurple}>you (all)</span>
                  </td>
                  <td>
                    Ọ da ghe{' '}
                    <span className={classes.highlightPurple}>bha</span>
                  </td>
                </tr>
                <tr>
                  <td className={classes.bottomBorder} />
                  <td className={classes.bottomBorder}>They</td>
                  <td className={classes.bottomBorder}>
                    He sees{' '}
                    <span className={classes.highlightPurple}>them</span>
                  </td>
                  <td className={classes.bottomBorder}>
                    Ọ da ghe{' '}
                    <span className={classes.highlightPurple}>ele</span>
                  </td>
                </tr>
                {/** Row 4 */}
                <tr>
                  <td>Reflexive</td>
                  <td>I</td>
                  <td>
                    I see <span className={classes.highlightGreen}>myself</span>
                  </td>
                  <td>
                    Mẹn da ghe{' '}
                    <span className={classes.highlightGreen}>egbemhẹn</span>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>You</td>
                  <td>
                    You see{' '}
                    <span className={classes.highlightGreen}>yourself</span>
                  </td>
                  <td>
                    Wẹ da ghe{' '}
                    <span className={classes.highlightGreen}>egbeẹ</span>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>He/She/It</td>
                  <td>
                    She sees{' '}
                    <span className={classes.highlightGreen}>herself</span>
                  </td>
                  <td>
                    Ọ da ghe{' '}
                    <span className={classes.highlightGreen}>egbọle</span>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>We</td>
                  <td>
                    We see{' '}
                    <span className={classes.highlightGreen}>ourselves</span>
                  </td>
                  <td>
                    Mhan da ghe{' '}
                    <span className={classes.highlightGreen}>egbemhan</span>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>You (all)</td>
                  <td>
                    You see{' '}
                    <span className={classes.highlightGreen}>yourselves</span>
                  </td>
                  <td>
                    Bha da ghe{' '}
                    <span className={classes.highlightGreen}>egbebha</span>
                  </td>
                </tr>
                <tr>
                  <td className={classes.bottomBorder} />
                  <td className={classes.bottomBorder}>They</td>
                  <td className={classes.bottomBorder}>
                    They see{' '}
                    <span className={classes.highlightGreen}>themselves</span>
                  </td>
                  <td className={classes.bottomBorder}>
                    E da ghe{' '}
                    <span className={classes.highlightGreen}>egbele</span>
                  </td>
                </tr>
                {/** Row 5 */}
                <tr>
                  <td>Possessor</td>
                  <td>My</td>
                  <td>
                    <span className={classes.highlightOrange}>My</span> dog
                  </td>
                  <td>
                    Awa{' '}
                    <span className={classes.highlightOrange}>nọ sẹ mhẹn</span>
                  </td>
                </tr>
                <tr>
                  <td>(general)</td>
                  <td>Your</td>
                  <td>
                    <span className={classes.highlightOrange}>Your</span> dog
                  </td>
                  <td>
                    Awa <span className={classes.highlightOrange}>nọ sẹ ẹ</span>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>His/Hers/Its</td>
                  <td>
                    <span className={classes.highlightOrange}>His</span> dog
                  </td>
                  <td>
                    Awa <span className={classes.highlightOrange}>nọ sọle</span>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>Our</td>
                  <td>
                    <span className={classes.highlightOrange}>Our</span> dog
                  </td>
                  <td>
                    Awa{' '}
                    <span className={classes.highlightOrange}>nọ sẹ mhan</span>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>(All) Your</td>
                  <td>
                    <span className={classes.highlightOrange}>Your</span> dog{' '}
                  </td>
                  <td>
                    Awa{' '}
                    <span className={classes.highlightOrange}>nọ sẹ bha</span>
                  </td>
                </tr>
                <tr>
                  <td className={classes.bottomBorder} />
                  <td className={classes.bottomBorder}>Their</td>
                  <td className={classes.bottomBorder}>
                    <span className={classes.highlightOrange}>Their</span> dog
                  </td>
                  <td className={classes.bottomBorder}>
                    Awa <span className={classes.highlightOrange}>nọ sele</span>
                  </td>
                </tr>
                {/** Row 6 */}
                <tr>
                  <td>Possessor</td>
                  <td>My</td>
                  <td>
                    <span className={classes.highlightPink}>My</span> father
                  </td>
                  <td>
                    Aba <span className={classes.highlightPink}>mẹn</span>
                  </td>
                </tr>
                <tr>
                  <td>(personal)</td>
                  <td>Your</td>
                  <td>
                    <span className={classes.highlightPink}>Your</span> father
                  </td>
                  <td>
                    Aba <span className={classes.highlightPink}>wẹ</span>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>His/Hers/Its</td>
                  <td>
                    <span className={classes.highlightPink}>His</span> father
                  </td>
                  <td>
                    Aba <span className={classes.highlightPink}>le</span>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>Our</td>
                  <td>
                    <span className={classes.highlightPink}>Our</span> father
                  </td>
                  <td>
                    Aba <span className={classes.highlightPink}>mhain</span>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>(All) Your</td>
                  <td>
                    <span className={classes.highlightPink}>Your</span> father
                  </td>
                  <td>
                    Aba <span className={classes.highlightPink}>bha</span>
                  </td>
                </tr>
                <tr>
                  <td className={classes.bottomBorder} />
                  <td className={classes.bottomBorder}>Their</td>
                  <td className={classes.bottomBorder}>
                    <span className={classes.highlightPink}>Their</span> father
                  </td>
                  <td className={classes.bottomBorder}>
                    Aba <span className={classes.highlightPink}>ele</span>
                  </td>
                </tr>
                {/** Row 7 */}
                <tr>
                  <td>Independant</td>
                  <td>My</td>
                  <td>
                    It's <span className={classes.highlightLime}>mine</span>
                  </td>
                  <td>
                    <span className={classes.highlightLime}>Ọsẹmhẹn</span> nọ
                  </td>
                </tr>
                <tr>
                  <td>possessor</td>
                  <td>Your</td>
                  <td>
                    It's <span className={classes.highlightLime}>yours</span>
                  </td>
                  <td>
                    <span className={classes.highlightLime}>Ọsẹẹ</span> nọ
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>His/Hers/Its</td>
                  <td>
                    It's <span className={classes.highlightLime}>his</span>
                  </td>
                  <td>
                    <span className={classes.highlightLime}>Ọsọle</span> nọ
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>Our</td>
                  <td>
                    It's <span className={classes.highlightLime}>ours</span>
                  </td>
                  <td>
                    <span className={classes.highlightLime}>Ọsẹmhan</span> nọ
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>(All) Your</td>
                  <td>
                    It's <span className={classes.highlightLime}>yours</span>
                  </td>
                  <td>
                    <span className={classes.highlightLime}>Ọsẹbha</span> nọ
                  </td>
                </tr>
                <tr>
                  <td className={classes.bottomBorder} />
                  <td className={classes.bottomBorder}>Their</td>
                  <td className={classes.bottomBorder}>
                    It's <span className={classes.highlightLime}>theirs</span>
                  </td>
                  <td className={classes.bottomBorder}>
                    <span className={classes.highlightLime}>Ọsẹle</span> nọ
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={12}>
            <p />
          </Grid>
        </Grid>
      </div>
    );
  }
}

PronounsPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const vals = [];
  state.intonationIds.forEach(id => {
    vals.push(state.words.filter(word => word.id === id).pop());
  });
  return {
    words: vals,
    wordIds: state.intonationIds
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startSetIntonationIdsAction: () => dispatch(startSetIntonationIds())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PronounsPage));
