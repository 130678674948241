import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/auth';
import wordsReducer from '../reducers/wordReducers';
import wordFiltersReducer from '../reducers/wordFilterReducers';
import alphabetWordIdsReducer from '../reducers/alphabetWordIdsReducer';
import countingWordIdsReducer from '../reducers/countingWordIdsReducer';
import intonationWordIdsReducer from '../reducers/intonationWordIdsReducer';
import bodyPartsWordIdsReducer from '../reducers/bodyPartsWordIdsReducer';
import householdWordIdsReducer from '../reducers/householdWordIdsReducer';
import animalsWordIdsReducer from '../reducers/animalsWordIdsReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Store creation
export default () => {
  const store = createStore(
    combineReducers({
      auth: authReducer,
      words: wordsReducer,
      wordFilters: wordFiltersReducer,
      alphabetIds: alphabetWordIdsReducer,
      countingIds: countingWordIdsReducer,
      intonationIds: intonationWordIdsReducer,
      vocabBodyPartsIds: bodyPartsWordIdsReducer,
      vocabHouseholdItemsIds: householdWordIdsReducer,
      vocabAnimalsIds: animalsWordIdsReducer
    }),
    composeEnhancers(applyMiddleware(thunk))
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  return store;
};
