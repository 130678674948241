import React from 'react';
import Radio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import WordDisplay from './WordDisplay';

const styles = theme => ({
  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  wordDisplayBody: {
    display: 'flex',
    alignItems: 'center'
  },
  wordDisplay: {
    margin: '10px'
  },
  root: {
    color: theme.palette.primary.dark,
    '&$checked': {
      color: theme.palette.primary.dark
    }
  },
  checked: {},
  '@media screen and (min-width: 15rem)': {},
  '@media screen and (min-width: 24rem)': {},
  '@media screen and (min-width: 30rem)': {}
});

class DualWordDisplay extends React.Component {
  constructor(props) {
    super(props);

    const nWords = [];
    for (let i = 0; i < props.wordPairs.length; i += 2) {
      if (
        props.wordPairs[i] === undefined ||
        props.wordPairs[i + 1] === undefined
      ) {
        break;
      }
      nWords.push({
        word1: props.wordPairs[i],
        word2: props.wordPairs[i + 1]
      });
    }

    const nHighs = [];
    for (let i = 0; i < props.highlightPairs.length; i += 2) {
      if (
        props.highlightPairs[i] === undefined ||
        props.highlightPairs[i + 1] === undefined
      ) {
        break;
      }
      nHighs.push({
        highlight1: props.highlightPairs[i],
        highlight2: props.highlightPairs[i + 1]
      });
    }

    this.state = {
      wordPairs: nWords,
      highlightPairs: nHighs,
      selectedValue: '0',
      selectedWord1: nWords[0].word1,
      selectedWord2: nWords[0].word2,
      highlight1: nHighs[0].highlight1,
      highlight2: nHighs[0].highlight2
    };
  }

  handleChange = e => {
    const { wordPairs, highlightPairs } = this.state;
    const newWord = wordPairs[e.target.value];
    const newHigh = highlightPairs[e.target.value];

    this.setState({
      selectedValue: e.target.value,
      selectedWord1: newWord.word1,
      selectedWord2: newWord.word2,
      highlight1: newHigh.highlight1,
      highlight2: newHigh.highlight2
    });
  };

  render() {
    const { classes } = this.props;
    const {
      wordPairs,
      selectedValue,
      selectedWord1,
      selectedWord2,
      highlight1,
      highlight2
    } = this.state;

    return (
      <div>
        {wordPairs.length > 1 ? (
          <div className={classes.body}>
            <div className={classes.wordDisplayBody}>
              <WordDisplay
                className={classes.wordDisplay}
                word={selectedWord1}
                highlight={highlight1}
              />
              <WordDisplay
                className={classes.wordDisplay}
                word={selectedWord2}
                highlight={highlight2}
              />
            </div>
            <div>
              {wordPairs.length > 1 &&
                wordPairs.map((wordPairItem, index) => {
                  return (
                    <Radio
                      key={index}
                      checked={selectedValue === `${index}`}
                      onChange={this.handleChange}
                      value={`${index}`}
                      name={`radio-button-${index}`}
                      aria-label={index}
                      classes={{
                        root: classes.root,
                        checked: classes.checked
                      }}
                    />
                  );
                })}
            </div>
          </div>
        ) : (
          <p> NULL</p>
        )}
      </div>
    );
  }
}

DualWordDisplay.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DualWordDisplay);
