// words REDUCER
// const alphabetWordsReducerDefaultState = [
//   'oO5aMgot4zISRILWvQCM',
//   'gVh9SZ9lXfnK2Jijrs7a',
//   'ayvKeumkfO2r7FYh6yM6',
//   'AyyJ2ZYLEgQAtSN7SpPZ',
//   'VRTYUMNfo689o9QpEJLR',
//   'VkUd0XKE7GayJHQpowNT',
//   'wUnzp5ibO97AxThcQJV0',
//   'vPoWdZi2JZsRCvQORwDL',
//   'rNwOcsvlstWZji4LcOBl',
//   'J31RxjXG5blkqQy64p40',
//   'AJuA7jpza9nTQg7kSAMA',
//   'SNddjj8iQH6qtnXVNfGd',
//   'etMnYxAxvxHaVl5zeSMC',
//   'DoMEGidKZDh0mKTVbVsW',
//   'kLOEaNUa40QohEaWRTXn',
//   'mxHI1haHf1nv3AcPJlIx',
//   'GmJIaKj5H8L5e60P10zc',
//   'Wf1NfZyRP7RI1NBmjf9G',
//   'Ur358Sexz7rI8rtqJ52u',
//   '7kdWe8cGzPoc3GwHbgjY',
//   'uD780dBNuKDXHPoXpNYY',
//   'MFoscnLwWwrIKRUccKUf',
//   'LgloURlgAgeYXG1M8qFL',
//   '9IpTxBHiQ4bkKtPuVjmb',
//   'IBsy3uJxaVQmKKaOBSUY',
//   'VSNlgPrYcg7xaSFfGqyu',
//   'o5cpcYvVQeAdPDOWhz1Y',
//   'BjFbEfpvY1QCDYHxOjhn',
//   'ExRFJ36QXqHG8mHTypRO',
//   'KpxOBOW6RrJecNew5I7v',
//   'rJNZAR1IWdOIW6hqWLW4',
//   'U3LLwdhC0ipaOx4LycgZ'
// ];
const alphabetWordsReducerDefaultState = [];

export default (state = alphabetWordsReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_ALPHABET_IDS':
      return action.ids;
    default:
      return state;
  }
};
