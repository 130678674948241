import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { firebase } from '../firebase/firebase';

export class PrivateRoute extends React.Component {
  state = {
    loading: true
  };

  componentDidMount() {
    const { isAuthenticated } = this.props;

    if (isAuthenticated) {
      this.setState(() => ({
        loading: false
      }));
    } else {
      this.removeListener = firebase.auth().onAuthStateChanged(user => {
        if (user) {
          // login(user.uid);
          this.setState(() => ({
            loading: false
          }));
        } else {
          // logout();
          this.setState(() => ({
            loading: false
          }));
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.removeListener !== undefined) {
      this.removeListener();
    }
  }

  loadOrWait = () => {
    const { location } = this.props;
    const { loading } = this.state;

    return loading ? (
      <div>LOADING</div>
    ) : (
      <Redirect to={{ pathname: '/', state: { from: location } }} />
    );
  };

  render() {
    const {
      isAuthenticated,
      isLoading,
      component: Component,
      ...rest
    } = this.props;

    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? <Component {...props} /> : this.loadOrWait()
        }
      />
    );
  }
}

const mapStateToProps = store => {
  return {
    isAuthenticated: !!store.auth.uid,
    isLoading: store.auth.loading
  };
};

export default connect(mapStateToProps)(PrivateRoute);
