import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import HeartIcon from '@material-ui/icons/Favorite';
import ComputerIcon from '@material-ui/icons/Computer';
import { Link } from 'react-router-dom';
import PageHead from '../components/PageHead';

const styles = theme => ({
  root: {
    flexGrow: 1,
    textDecoration: 'none'
  },
  header: {
    textAlign: 'center'
  },
  body: {
    fontSize: '1.35em'
  },
  interactBox: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  link: {
    textDecoration: 'none',
    color: 'black'
  },
  icon: {
    color: 'black',
    fontSize: '1.5em'
  },
  itemBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '1.3em'
  },
  '@media screen and (max-width: 20rem)': {
    body: {
      fontSize: '1.00em'
    },
    itemBox: {
      fontSize: '1.1em'
    }
  }
});

export const AboutPage = ({ classes }) => (
  <div className={classes.root}>
    <PageHead
      title="About Us"
      description="About the LearningEsan Project"
      keywords="esan,ishan,edo,nigeria,learn,about,language"
    />
    <h1 className={classes.header}>Our Mission</h1>

    <Typography className={classes.body}>
      This site will serve as an online resource for learning the fundamentals
      of the Esan language and the history of the Esan people. This site (and
      forthcoming mobile app) will gradually grow with interactive material,
      audio, articles, and historical texts.
      <br />
      <br />
      If you are interested in contributing or learning more feel free to
      contact us.
      <br />
      <br />~ The Learning Esan Team
    </Typography>
    <br />
    <br />
    <div className={classes.interactBox}>
      <div className={classes.itemBox}>
        <Link to="/contact">
          <EmailIcon className={classes.icon} />
        </Link>
        <Link to="/contact" className={classes.link}>
          Contact Us
        </Link>
      </div>
      <div className={classes.itemBox}>
        <a
          href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=W2PD6GD8GBRRU"
          target="_blank"
          rel="noopener noreferrer"
        >
          <HeartIcon className={classes.icon} />
        </a>
        <a
          href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=W2PD6GD8GBRRU"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          Donate
        </a>
      </div>
      <div className={classes.itemBox}>
        <a
          href="https://www.linkedin.com/in/george-abumere/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ComputerIcon className={classes.icon} />
        </a>
        <a
          href="https://www.linkedin.com/in/george-abumere/"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          LinkedIn
        </a>
      </div>
    </div>
  </div>
);

AboutPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AboutPage);
