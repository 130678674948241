import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import DataTable from '../components/DataTable/DataTable';

const DataTablePage = props => {
  const { words } = props;
  const tableOptions = {
    onRowClick: rowData => {
      const { history } = props;
      history.push(`/dashboard/word/edit/${rowData[0]}`);
    }
  };

  const handleDeleteWord = word => {
    const { startRemoveWordAction } = props;
    return startRemoveWordAction(word);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <DataTable
            title="Word List"
            wordData={words}
            tableOptions={tableOptions}
            handleDeleteRow={handleDeleteWord || {}}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    words: state.words
  };
};
export default connect(mapStateToProps)(DataTablePage);
