// words REDUCER
// const householdWordReducerDefaultState = [
//   'dlAsRLdYo0qcQ0O7vIs8',
//   'XxA9dAeDu84IttN5qyg0',
//   'nxhAjepsXmsSkSWxvFJw',
//   'PIora1eVFtOmuAbfnvUI',
//   'rNwOcsvlstWZji4LcOBl',
//   '51OhluklLezCnBaM9Cmw',
//   'MTErd6mhSaT16GvgpdT2',
//   'WX9OPldIbddQy8XapeXm',
//   'hVTfB6FGODCublORsExh',
//   'VHCYqOKZOOatsIJFBGGA',
//   'p3FW8XgdCS4OejmCw1TS',
//   'xNnsizDGAh6KFX9v47Pe',
//   't37ydbcNpvYMZnpOhsZS'
// ];
const householdWordReducerDefaultState = [];

export default (state = householdWordReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_HOUSE_IDS':
      return action.ids;
    default:
      return state;
  }
};
