import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AlphabetIcon from '@material-ui/icons/SortByAlpha';
import NumberedList from '@material-ui/icons/FormatListNumbered';
import VolumeUp from '@material-ui/icons/VolumeUp';
import Book from '@material-ui/icons/Book';
import Spa from '@material-ui/icons/Spa';
import Merge from '@material-ui/icons/CallMerge';
import Face from '@material-ui/icons/Face';
import Grid from '@material-ui/core/Grid';
import PageHead from '../components/PageHead';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import LearningSectionsCard from '../components/SectionCard';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap'
  },
  grow: {
    flexGrow: 1
  },
  title: {
    fontFamily: '"Baloo Chettan", cursive',
    letterSpacing: '.06em',
    textAlign: 'center'
  },
  '@media screen and (min-width: 20rem)': {
    title: {
      fontSize: '0.8em'
    }
  },
  '@media screen and (min-width: 24rem)': {
    title: {
      fontSize: '0.8em'
    }
  },
  '@media screen and (min-width: 30rem)': {
    title: {
      fontSize: '1.3em'
    }
  }
};

export const LearningSectionsPage = ({ classes }) => (
  <div>
    <PageHead
      title="Language Sections"
      description="Pick a category to start learning Esan"
      keywords="esan,words,esan words,ishan,edo,nigeria,learn,sections,category,categories,language"
    />
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={classes.title}>
          <h1>Beginner</h1>
        </div>
      </Grid>
      <Grid item xs={6} sm={4}>
        <LearningSectionsCard title="Alphabet" pagePath="/learn/alphabet">
          <AlphabetIcon />
        </LearningSectionsCard>
      </Grid>
      <Grid item xs={6} sm={4}>
        <LearningSectionsCard title="Counting" pagePath="/learn/counting">
          <NumberedList />
        </LearningSectionsCard>
      </Grid>
      <Grid item xs={6} sm={4}>
        <LearningSectionsCard title="Common Vocabulary" pagePath="/learn/words">
          <Book />
        </LearningSectionsCard>
      </Grid>
      <Grid item xs={6} sm={4}>
        <LearningSectionsCard
          title="Esan Names And Meanings"
          pagePath="/learn/names"
        >
          <Spa />
        </LearningSectionsCard>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.title}>
          <h1>Intermediate</h1>
        </div>
      </Grid>
      <Grid item xs={6} sm={4}>
        <LearningSectionsCard title="Intonation" pagePath="/learn/intonation">
          <VolumeUp />
        </LearningSectionsCard>
      </Grid>
      <Grid item xs={6} sm={4}>
        <LearningSectionsCard title="Vowel Elision" pagePath="/learn/elision">
          <Merge />
        </LearningSectionsCard>
      </Grid>
      <Grid item xs={6} sm={4}>
        <LearningSectionsCard title="Pronouns" pagePath="/learn/pronouns">
          <Face />
        </LearningSectionsCard>
      </Grid>
    </Grid>
  </div>
);

LearningSectionsPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LearningSectionsPage);
