import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import WordDisplay from '../../components/Words/WordDisplay';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap'
  },
  grow: {
    flexGrow: 1
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '1em'
  },
  title: {
    letterSpacing: '.06em',
    textAlign: 'center'
  },
  '@media screen and (min-width: 20rem)': {
    title: {
      fontSize: '0.7em'
    }
  },
  '@media screen and (min-width: 24rem)': {
    title: {
      fontSize: '0.8em'
    }
  },
  '@media screen and (min-width: 30rem)': {
    title: {
      fontSize: '1em'
    }
  }
};

export const WordDisplayPage = ({ classes, word }) => (
  <div>
    <div className={classes.body}>
      <WordDisplay word={word} />
    </div>
  </div>
);

WordDisplayPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    word: state.words.filter(word => word.id === ownProps.match.params.id).pop()
  };
};

export default connect(mapStateToProps)(withStyles(styles)(WordDisplayPage));
