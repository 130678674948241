import db from '../firebase/firebase';

export const setBodyIds = ids => ({
  type: 'SET_BODY_IDS',
  ids
});

export const startSetBodyIds = () => {
  return dispatch => {
    return db
      .collection('esan-pages')
      .doc('vocab-body-parts-page')
      .get()
      .then(docRef => {
        const obj = docRef.data();
        const output = [];

        Object.getOwnPropertyNames(obj).forEach(item => {
          output.push(obj[item]);
        });
        dispatch(setBodyIds(output));
        return Promise.resolve(output);
      });
  };
};
