import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PageHead from '../components/PageHead';
import WordDisplay from '../components/Words/WordDisplay';
import WordDisplayButton from '../components/Buttons/WordDisplayButton';
import { startSetAlphabetIds } from '../actions/alphabetWordActions';

const styles = {
  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '1em'
  },
  header: {
    textAlign: 'center'
  },
  alphabetBody: {
    marginTop: '10px',
    textAlign: 'center'
  },
  button: {
    padding: '6px 12px 6px 12px',
    border: 1,
    margin: 2,
    cursor: 'pointer',
    fontWeight: 'bold',
    color: 'black'
  },
  '@media screen and (min-width: 25rem)': {
    button: {
      fontSize: '1.3em'
    }
  },
  '@media screen and (min-width: 30rem)': {
    button: {
      fontSize: '2em'
    }
  }
};
const letters = [
  'A',
  'B',
  'Bh',
  'Ch',
  'D',
  'E',
  'Ẹ',
  'F',
  'G',
  'Gb',
  'Gh',
  'H',
  'I',
  'J',
  'K',
  'Kh',
  'Kp',
  'L',
  'M',
  'N',
  'O',
  'Ọ',
  'P',
  'R',
  'S',
  'Sh',
  'T',
  'U',
  'V',
  'W',
  'Y',
  'Z'
];

// const nasalVowels = ['an', 'ẹn', 'in', 'ọn', 'un'];
// const doubleConsonants = ['bh', 'ch', 'gb', 'gh', 'kh', 'kp', 'sh'];

export class AlphabetPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedWord: {
        word: undefined,
        letter: undefined
      }
    };
  }

  componentDidMount() {
    const { startSetAlphabetAction } = this.props;
    startSetAlphabetAction().then(() => {
      const { wordIds } = this.props;
      this.handleButtonPressed(wordIds[0], 'A');
    });
  }

  handleButtonPressed = (id, letter) => {
    const { words } = this.props;
    const word = words.filter(wordItem => wordItem.id === id).pop();
    const selectedWord = {
      word,
      letter
    };

    this.setState(() => ({
      selectedWord
    }));
  };

  render() {
    const { classes, wordIds } = this.props;
    const { selectedWord } = this.state;

    return (
      <div>
        <PageHead
          title="The Esan Alphabet"
          description="Learn The Esan Alphabet, vowels, and consonants"
          keywords="esan,ishan,edo,nigeria,learn,alphabet,letters,words,esan words,vowels,consonants,language"
        />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h1 className={classes.header}>The Alphabet</h1>
          </Grid>
          <Grid item xs={12} className={classes.body}>
            {!!selectedWord.word && (
              <WordDisplay
                word={selectedWord.word}
                highlight={selectedWord.letter}
              />
            )}
          </Grid>
          {letters.map((item, index) => (
            <Grid key={item} item xs={2} sm={1}>
              <WordDisplayButton
                highlight={item}
                wordId={wordIds[index]}
                onButtonClicked={this.handleButtonPressed}
              >
                {item}
              </WordDisplayButton>
            </Grid>
          ))}
          {/** <Grid item xs={12}>
            <h1 className={classes.header}>Nasal Vowels</h1>
          </Grid>
          <Grid item xs={12}>
            <h1 className={classes.header}>Double Consonants</h1>
          </Grid>
          */}
        </Grid>
      </div>
    );
  }
}

AlphabetPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const vals = [];
  state.alphabetIds.forEach(id => {
    let foundWord = state.words.filter(word => word.id === id).pop();
    foundWord = foundWord || {};
    vals.push(foundWord);
  });

  return {
    words: vals,
    wordIds: state.alphabetIds
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startSetAlphabetAction: () => dispatch(startSetAlphabetIds())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AlphabetPage));
