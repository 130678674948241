import React from 'react';
import MUIDataTable from 'mui-datatables';
import { connect } from 'react-redux';

const columns = [
  {
    name: 'id',
    label: 'ID',
    options: {
      filter: true,
      sort: false
    }
  },
  {
    name: 'english',
    label: 'Word',
    options: {
      filter: true,
      sort: true
    }
  },
  // {
  //   name: 'pos',
  //   label: 'Pos',
  //   options: {
  //     filter: true,
  //     sort: true
  //   }
  // },
  {
    name: 'translationLit',
    label: 'Translation',
    options: {
      filter: true,
      sort: true
    }
  },
  // {
  //   name: 'translation',
  //   label: 'Translation',
  //   options: {
  //     filter: true,
  //     sort: true,
  //     display: true,
  //     searchable: true
  //   }
  // },
  {
    name: 'imageName',
    label: 'Image',
    options: {
      filter: true,
      sort: true
    }
  },
  // {
  //   name: 'image',
  //   label: 'Image',
  //   options: {
  //     filter: true,
  //     sort: true
  //   }
  // },
  {
    name: 'audioName',
    label: 'Audio',
    options: {
      filter: true,
      sort: true
    }
  }
  // {
  //   name: 'audio',
  //   label: 'Audio',
  //   options: {
  //     filter: true,
  //     sort: true
  //   }
  // }
];

class DataTable extends React.Component {
  state = {};

  onTableChange = (action, tableState) => {
    const wordsPicked = [];
    const wordsFromRows = [];
    const { selectedWords } = this.state;
    const { handleDeleteRow, words } = this.props;

    switch (action) {
      case 'rowsSelect':
        tableState.selectedRows.data.forEach(row => {
          const foundWord = tableState.data
            .filter(word => word.index === row.dataIndex)
            .pop();
          wordsPicked.push(foundWord.data);
        });
        wordsPicked.forEach(row => {
          let foundWord = words.filter(word => word.id === row[0]).pop();
          foundWord = foundWord || {};
          wordsFromRows.push(foundWord);
        });

        this.setState(prevState => ({
          ...prevState,
          selectedWords: wordsFromRows
        }));
        break;
      case 'rowDelete':
        selectedWords.forEach(word => {
          handleDeleteRow(word);
        });
        break;
      default:
        break;
    }
  };

  render() {
    const { title, wordData, tableOptions } = this.props;

    tableOptions.onTableChange = this.onTableChange;

    return (
      <MUIDataTable
        title={title}
        data={wordData}
        columns={columns}
        options={tableOptions}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    words: state.words
  };
};
export default connect(mapStateToProps)(DataTable);
