// words REDUCER
// const countingWordsReducerDefaultState = [
//   'xSp4p8uZFGHwB2rJ7Zao',
//   '3sHLSC2gbqPgB6NtqOb6',
//   'ffteVKc1ysPBAydJO8Xk',
//   'h4DBo3RSpgmyaDEQTaJZ',
//   'SeFS6kpnOtlwxHeFODpH',
//   'E9hCF5uHAzZrd331HXV2',
//   'MhtUaGSJbTV3A50XQJ1z',
//   'WIAyDKJHEJLXY3bAdRTG',
//   'iXNAXXZvRSAAiAnHhRmR',
//   '2VTrw188BE8Wjb8uZCHo',
//   'QDyXM7jTUF3eNK1lLrjE',
//   '1wWJ4es2kbEtioWmFpES',
//   'xBNWGqJtD4tYoqOV99bN',
//   'CCoNnioghsjJyGv0fhhi',
//   'XpFKsTbZGTXTmBlvNl2u',
//   'BAeiNJZC99GUieAy147y',
//   '3H7IxplWMK57Tsikpfv8',
//   'j3gLGCR2cIxRHYro6cIe',
//   '2u8bY6lGA4iCQTY5Zwuq',
//   '6AplXYoUdGuow0fZhHwt',
//   'EIli8z7NZ1JNEAYwrAzC',
//   'qFEfREdAKgXZ7aCOOcGA',
//   'mYVb1qm9ka6sKb9IUlx6',
//   'wNN6FXu3gI5cBDKsajCs',
//   'DoHnE1XWsV4Q5EiBpSIZ',
//   '9hoCeImSbMhfPiC1yueP',
//   'XHihi8O1Z5BjAxzERNiX',
//   '9vSj2xeSBy6BS1VGnCUq'
// ];

const countingWordsReducerDefaultState = [];

export default (state = countingWordsReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_COUNTING_IDS':
      return action.ids;
    default:
      return state;
  }
};
