import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { firebase, uiConfig } from '../firebase/firebase';
import DashboardWidget from '../components/DashboardWidget';

const styles = theme => ({
  root: {
    flexGrow: 1,
    textAlign: 'center'
  },
  widget: {
    width: '300px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
});

const SignInPage = props => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1>Sign In Below</h1>
        </Grid>
        <Grid item xs={12}>
          <h2>Login is currently for Administrators only</h2>
          <h4>General User Accounts Coming Soon</h4>
        </Grid>
        <Grid item xs={12}>
          <DashboardWidget className={classes.widget}>
            <StyledFirebaseAuth
              uiConfig={uiConfig}
              firebaseAuth={firebase.auth()}
              uiCallback={ui => ui.disableAutoSignIn()}
            />
          </DashboardWidget>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(SignInPage);
