import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import AlphabetIcon from '@material-ui/icons/SortByAlpha';
import AddPhoto from '@material-ui/icons/AddPhotoAlternate';
// eslint-disable-next-line import/no-extraneous-dependencies
import compose from 'recompose/compose';
import withWidth from '@material-ui/core/withWidth';
// eslint-disable-next-line import/no-extraneous-dependencies
import classNames from 'classnames';
import { Link } from 'react-router-dom';
// core components

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex'
  },
  drawer: {
    elevation: 0,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'relative'
  },
  drawerSmall: {
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  toolbar: theme.mixins.toolbar,
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  }
});

const navOptions = [
  {
    text: 'Dashboard',
    icon: <DashboardIcon />,
    path: '/dashboard'
  },
  {
    text: 'New Word',
    icon: <AddPhoto />,
    path: '/dashboard/word/create/'
  },
  {
    text: 'Word List',
    icon: <AlphabetIcon />,
    path: '/dashboard/words'
  },
  {
    text: 'User List',
    icon: <PeopleIcon />,
    path: '/dashboard'
  }
];

const Sidebar = props => {
  const { classes, width } = props;
  const showMini = width === 'xs' || width === 'sm';
  return (
    <Drawer
      className={classNames(classes.drawer, {
        [classes.drawerClose]: showMini
      })}
      variant="permanent"
      classes={{
        paper: classNames(classes.drawerPaper, {
          [classes.drawerClose]: showMini
        })
      }}
    >
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {navOptions.map(linkObj => (
          <Link key={linkObj.text} to={linkObj.path}>
            <ListItem button key={linkObj.text}>
              <ListItemIcon>{linkObj.icon}</ListItemIcon>
              {!showMini && <ListItemText primary={linkObj.text} />}
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
    </Drawer>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles),
  withWidth()
)(Sidebar);
