import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PageHead from '../../components/PageHead';
import WordDisplay from '../../components/Words/WordDisplay';
import WordDisplayButton from '../../components/Buttons/WordDisplayButton';
import { startSetHousholdIds } from '../../actions/householdWordActions';

const styles = {
  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '1em'
  },
  itemsBody: {
    marginTop: '10px',
    textAlign: 'center'
  }
};

export class HouseholdItemsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedWord: {
        word: undefined
      }
    };
  }

  componentDidMount() {
    const { startSetHouseholdIdsAction } = this.props;
    startSetHouseholdIdsAction().then(() => {
      const { wordIds } = this.props;
      this.handleButtonPressed(wordIds[0]);
    });
  }

  handleButtonPressed = id => {
    const { words } = this.props;
    const word = words.filter(wordItem => wordItem.id === id).pop();
    const selectedWord = {
      word
    };

    this.setState(() => ({
      selectedWord
    }));
  };

  render() {
    const { classes, wordIds } = this.props;
    const { selectedWord } = this.state;

    return (
      <div>
        <PageHead
          title="Vocabulary - Household Items"
          description="Items In and Around The House in Esan"
          keywords="esan,ishan,edo,nigeria,learn,home,house,household,vocabulary,language"
        />
        <div className={classes.body}>
          <h1>In and Around the Home</h1>
          {!!selectedWord && !!selectedWord.word && (
            <div className={classes.body}>
              <WordDisplay word={selectedWord.word} />
            </div>
          )}
          <div className={classes.itemsBody}>
            <WordDisplayButton
              wordId={wordIds[0]}
              onButtonClicked={this.handleButtonPressed}
            >
              House
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[1]}
              onButtonClicked={this.handleButtonPressed}
            >
              Roof
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[2]}
              onButtonClicked={this.handleButtonPressed}
            >
              Door
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[3]}
              onButtonClicked={this.handleButtonPressed}
            >
              Mat
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[4]}
              onButtonClicked={this.handleButtonPressed}
            >
              Chair
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[5]}
              onButtonClicked={this.handleButtonPressed}
            >
              Bed
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[6]}
              onButtonClicked={this.handleButtonPressed}
            >
              Pot
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[7]}
              onButtonClicked={this.handleButtonPressed}
            >
              Cup
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[8]}
              onButtonClicked={this.handleButtonPressed}
            >
              Spoon
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[9]}
              onButtonClicked={this.handleButtonPressed}
            >
              Mouse Trap
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[10]}
              onButtonClicked={this.handleButtonPressed}
            >
              Matches
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[11]}
              onButtonClicked={this.handleButtonPressed}
            >
              Tobacco Pipe
            </WordDisplayButton>
            <WordDisplayButton
              wordId={wordIds[12]}
              onButtonClicked={this.handleButtonPressed}
            >
              Bottle
            </WordDisplayButton>
          </div>
        </div>
      </div>
    );
  }
}

HouseholdItemsPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const vals = [];
  state.vocabHouseholdItemsIds.forEach(id => {
    let foundWord = state.words.filter(word => word.id === id).pop();
    foundWord = foundWord || {};
    vals.push(foundWord);
  });

  return {
    words: vals,
    wordIds: state.vocabHouseholdItemsIds
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startSetHouseholdIdsAction: () => dispatch(startSetHousholdIds())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(HouseholdItemsPage));
