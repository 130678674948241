import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';
import AboutPage from '../pages/AboutPage';
import AlphabetPage from '../pages/AlphabetPage';
import Animals from '../pages/Vocabulary/AnimalsPage';
import BodyPartsPage from '../pages/Vocabulary/BodyPartsPage';
import CountingPage from '../pages/CountingPage';
import ContactPage from '../pages/ContactPage';
import DictionaryPage from '../pages/DictionaryPage';
import IntonationPage from '../pages/IntonationPage';
import ElisionPage from '../pages/ElisionPage';
import PronounsPage from '../pages/PronounsPage';
import VocabularyPage from '../pages/VocabularyPage';
import EsanNamesPage from '../pages/EsanNamesPage';
import LearningSectionsPage from '../pages/LearningSectionsPage';
import NotFoundPage from '../pages/NotFoundPage';
import HouseholdItemsPage from '../pages/Vocabulary/HouseholdItemsPage';
import WordDisplayPage from '../pages/Words/WordDisplayPage';
import SignInPage from '../pages/SignInPage';
import PublicRoute from './PublicRoute';

export const history = createBrowserHistory();

const styles = theme => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    maxWidth: '1140px',
    margin: '0 auto'
  },
  toolbar: theme.mixins.toolbar
});

const AppRouter = ({ classes }) => (
  <div className={classes.content}>
    <div className={classes.toolbar} />
    <Switch>
      <PublicRoute path="/login" component={SignInPage} />
      <Route path="/about" component={AboutPage} />
      <Route path="/contact" component={ContactPage} />
      <Route path="/dictionary" component={DictionaryPage} />
      <Route path="/learn" component={LearningSectionsPage} exact />
      <Route path="/learn/alphabet" component={AlphabetPage} />
      <Route path="/learn/counting" component={CountingPage} />
      <Route path="/learn/intonation" component={IntonationPage} />
      <Route path="/learn/elision" component={ElisionPage} />
      <Route path="/learn/pronouns" component={PronounsPage} />
      <Route path="/learn/words" component={VocabularyPage} exact />
      <Route path="/learn/words/body-parts" component={BodyPartsPage} exact />
      <Route path="/learn/names" component={EsanNamesPage} exact />
      <Route
        path="/learn/words/household-items"
        component={HouseholdItemsPage}
      />
      <Route path="/learn/words/animals" component={Animals} exact />
      <Route path="/words/:id" component={WordDisplayPage} />
      <Route component={NotFoundPage} />
    </Switch>
  </div>
);

export default withStyles(styles)(AppRouter);
