// SET_TEXT_FILTER
export const setWordsTextFilter = (text = '') => ({
  type: 'SET_TEXT_FILTER',
  text
});

// SORT_BY_ENGLISH
export const sortWordsByEnglish = () => ({
  type: 'SORT_BY_ENGLISH'
});

// SORT_BY_TRANS
export const sortWordsByTranslation = () => ({
  type: 'SORT_BY_TRANSLATION'
});

// SET_START_DATE
export const setStartDate = (startDate = undefined) => ({
  type: 'SET_START_DATE',
  startDate
});
