import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

import { startLoginAction, startLogoutAction } from '../actions/auth';

const styles = theme => ({
  appBar: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
    background: '#333333'
  },
  transparentAppBar: {
    background: 'transparent',
    boxShadow: 'none'
  },
  dropDownMenu: {
    background: '#333333'
  },
  toolbar: {
    width: '100%',
    maxWidth: '1140px',
    margin: '0 auto'
  },
  loginButton: {
    background: 'transparent',
    boxShadow: 'none',
    color: 'white',
    textDecoration: 'none'
  },
  popper: {
    zIndex: theme.zIndex.appBar
  },
  title: {
    flexGrow: 1
  },
  headerItem: {
    textDecoration: 'none',
    color: 'white',
    background: 'transparent',
    boxShadow: 'none',
    paddingLeft: 10,
    paddingRight: 10
  },
  headerActive: {
    color: theme.palette.primary.main
  },
  menuButton: {
    margin: 10,
    color: theme.palette.primary.main
  },
  menuIcon: {
    fontSize: '1.2em'
  },
  menuItem: {
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  menuTitle: {
    color: theme.palette.primary.main,
    fontFamily: '"Kaushan Script", "Helvetica Neue", Helvetica, Arial, cursive',
    fontWeight: 'bold',
    fontSize: '1.05em',
    textDecoration: 'none',
    letterSpacing: '.06em',
    lineHeight: 2,
    marginRight: 25
  },
  '@media screen and (min-width: 15rem)': {
    toolbar: {
      padding: '0 20px'
    },
    menuButton: {
      padding: '1px 6px',
      margin: '5px 0 0 0'
    }
  },
  '@media screen and (min-width: 45rem)': {
    toolbar: {
      padding: '0 40px'
    }
  }
});

const headerLinks = [
  {
    name: 'Learn',
    to: '/learn'
  },
  {
    name: 'Dictionary',
    to: '/dictionary'
  },
  {
    name: 'About Us',
    to: '/about'
  },
  {
    name: 'Contact',
    to: '/contact'
  }
];

export class Header extends React.Component {
  state = {
    anchorEl: null
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSignIn = () => {
    const { startLogin, history } = this.props;
    this.handleClose();
    startLogin().then(() => {
      history.push('/dashboard');
    });
  };

  handleSignOut = () => {
    const { startLogout, history } = this.props;
    this.handleClose();
    startLogout().then(() => {
      history.push('/');
    });
  };

  render() {
    const { classes, userId, location } = this.props;
    // const { auth, anchorEl } = this.state;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const { pathname } = location;
    const isHome = pathname === '/';
    const buttons = [
      {
        name: 'Learn',
        path: '/learn'
      },
      {
        name: 'Dictionary',
        path: '/dictionary'
      },
      {
        name: 'About Us',
        path: '/about'
      },
      {
        name: 'Contact',
        path: '/contact'
      }
    ];

    const NavLinkUp = React.forwardRef((props, ref) => (
      <NavLink innerRef={ref} {...props} />
    ));

    return (
      <AppBar
        className={`${classes.appBar} ${isHome && classes.transparentAppBar}`}
      >
        <Toolbar className={classes.toolbar}>
          <Typography variant="h5" color="inherit" className={classes.title}>
            <NavLink to="/" exact className={classes.menuTitle}>
              Learning Esan
            </NavLink>
            <Hidden smDown>
              {headerLinks.map(linkObj => (
                <Button
                  key={linkObj.name}
                  component={NavLinkUp}
                  to={linkObj.to}
                  className={`${classes.headerItem} ${linkObj.to === pathname &&
                    classes.headerActive}`}
                  variant="contained"
                  color="primary"
                >
                  {linkObj.name}
                </Button>
              ))}
            </Hidden>
          </Typography>

          <Hidden smDown>
            {userId ? (
              <Button
                variant="contained"
                color="primary"
                className={classes.loginButton}
                onClick={this.handleSignOut}
              >
                Logout
              </Button>
            ) : (
              <Button
                component={NavLinkUp}
                to="/login"
                className={`${classes.headerItem} ${pathname === '/login' &&
                  classes.headerActive}`}
                variant="contained"
                color="primary"
              >
                Login
              </Button>
            )}
          </Hidden>
          <Hidden mdUp>
            <IconButton
              className={classes.menuButton}
              onClick={this.handleMenu}
              color="inherit"
              aria-label="Menu"
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </Hidden>
          <Popper
            id="menu-appbar"
            anchorEl={anchorEl}
            open={open}
            onClose={this.handleClose}
            transition
            className={classes.popper}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom'
                }}
              >
                <Paper className={classes.dropDownMenu}>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList>
                      {buttons.map(item => (
                        <MenuItem onClick={this.handleClose}>
                          <NavLink
                            to={item.path}
                            activeClassName="is-active"
                            className={classes.menuItem}
                          >
                            {item.name}
                          </NavLink>
                        </MenuItem>
                      ))}

                      <MenuItem
                        onClick={this.handleClose}
                        className={classes.menuItem}
                      >
                        <a
                          href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=W2PD6GD8GBRRU"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.menuItem}
                        >
                          Donate
                        </a>
                      </MenuItem>
                      {userId && (
                        <MenuItem onClick={this.handleClose}>
                          <NavLink
                            to="/dashboard"
                            activeClassName="is-active"
                            className={classes.menuItem}
                          >
                            Dashboard
                          </NavLink>
                        </MenuItem>
                      )}
                      <Divider />
                      <Divider />
                      {!userId && (
                        <MenuItem onClick={this.handleClose}>
                          <NavLink
                            to="/login"
                            activeClassName="is-active"
                            className={classes.menuItem}
                          >
                            Sign In
                          </NavLink>
                        </MenuItem>
                      )}
                      {userId && (
                        <MenuItem
                          onClick={this.handleSignOut}
                          className={classes.menuItem}
                        >
                          Sign Out
                        </MenuItem>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = store => ({
  userId: store.auth.uid
});

const mapDispatchToProps = dispatch => ({
  startLogin: () => dispatch(startLoginAction()),
  startLogout: () => dispatch(startLogoutAction())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    pure: false
  }
)(withStyles(styles)(withRouter(Header)));
