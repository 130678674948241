import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import WordForm from '../../components/Words/WordForm';
import { startEditWord, startRemoveWord } from '../../actions/wordActions';

const styles = {
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    letterSpacing: '.06em',
    textAlign: 'center'
  },
  '@media screen and (min-width: 20rem)': {
    title: {
      fontSize: '0.7em'
    }
  },
  '@media screen and (min-width: 24rem)': {
    title: {
      fontSize: '0.8em'
    }
  },
  '@media screen and (min-width: 30rem)': {
    title: {
      fontSize: '1em'
    }
  }
};

class EditWordPage extends React.Component {
  saveWord = word => {
    const { startEditWordAction } = this.props;

    const newWord = {
      ...word,
      id: word.id,
      translation: word.translation.toLowerCase().trim(),
      translationLit: word.translationLit.toLowerCase().trim(),
      english: word.english.toLowerCase().trim()
    };

    return startEditWordAction(newWord);
  };

  removeWord = word => {
    const { startRemoveWordAction, history } = this.props;

    return startRemoveWordAction(word).then(() => {
      history.push('/dashboard');
    });
  };

  render() {
    const { classes, word } = this.props;
    return (
      <div className={classes.title}>
        {!!word && (
          <WordForm
            title={'Edit Word'}
            handleWordSubmit={this.saveWord}
            handleWordDelete={this.removeWord}
            word={word}
          />
        )}
      </div>
    );
  }
}

EditWordPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    words: state.words,
    word: state.words.filter(word => word.id === ownProps.match.params.id).pop()
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startEditWordAction: word => dispatch(startEditWord(word)),
    startRemoveWordAction: word => dispatch(startRemoveWord(word))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditWordPage));
