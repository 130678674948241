// FILTERS REDUCER
const filtersReducerDefaultState = {
  text: '',
  sortBy: 'translation',
  startDate: undefined,
  endDate: undefined
};
export default (state = filtersReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_TEXT_FILTER':
      return {
        ...state,
        text: action.text
      };
    case 'SORT_BY_TRANSLATION':
      return {
        ...state,
        sortBy: 'translation'
      };
    case 'SORT_BY_ENGLISH':
      return {
        ...state,
        sortBy: 'english'
      };
    case 'SET_START_DATE':
      return {
        ...state,
        startDate: action.startDate
      };
    case 'SET_END_DATE':
      return {
        ...state,
        endDate: action.endDate
      };
    default:
      return state;
  }
};
