import { firebase, facebookAuthProvider } from '../firebase/firebase';

export const loginAction = uid => ({
  type: 'LOGIN',
  uid
});

// eslint-disable-next-line import/prefer-default-export
export const startLoginAction = () => {
  return () => {
    return firebase.auth().signInWithPopup(facebookAuthProvider);
  };
};

export const logoutAction = () => ({
  type: 'LOGOUT'
});

export const startLogoutAction = () => {
  return () => {
    return firebase.auth().signOut();
  };
};

export const startLoadingAction = () => ({
  type: 'START_LOADING',
  loading: true
});

export const stopLoadingAction = () => ({
  type: 'STOP_LOADING',
  loading: false
});
