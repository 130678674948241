import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../routers/PrivateRoute';
import Sidebar from '../components/Sidebar/Sidebar';
import DashboardPage from '../pages/DashboardPage';
import AddWordPage from '../pages/Words/AddWordPage';
import EditWordPage from '../pages/Words/EditWordPage';
import WordTablePage from '../pages/WordTablePage';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
    margin: 10
  },
  drawer: {
    elevation: 0,
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    margin: '0 auto'
  },
  toolbar: theme.mixins.toolbar
});

const DashboardLayout = props => {
  const { classes } = props;
  return (
    <div className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.root}>
        <Sidebar />
        <Switch>
          <PrivateRoute path="/dashboard" component={DashboardPage} exact />
          <PrivateRoute path="/dashboard/word/create" component={AddWordPage} />
          <PrivateRoute
            path="/dashboard/word/edit/:id"
            component={EditWordPage}
          />
          <PrivateRoute path="/dashboard/words" component={WordTablePage} />
        </Switch>
      </div>
    </div>
  );
};

export default withStyles(styles)(DashboardLayout);
