import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import VolumeUp from '@material-ui/icons/VolumeUpRounded';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Howl } from 'howler';

const styles = theme => ({
  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '1em',
    margin: 10,
    textAlign: 'center'
  },
  media: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderStyle: 'solid',
    borderWidth: '2px'
  },
  card: {
    marginBottom: '8px'
  },
  icon: {
    fontSize: '25px'
  },
  button: {
    margin: 0,
    padding: 5,
    color: theme.palette.primary.dark
  },
  translationWord: {
    fontSize: '1.9em',
    cursor: 'pointer',
    outline: 'none'
  },
  englishWord: {
    fontSize: '1.35em',
    fontStyle: 'italic',
    color: '#929292'
  },
  hightLight: {
    fontWeight: 'bold',
    fontSize: '1.05em',
    color: theme.palette.primary.dark
  },
  '@media screen and (min-width: 15rem)': {
    button: {
      fontSize: '1.35em'
    },
    cardActionArea: {
      width: 150,
      height: 150
    },
    body: {
      margin: 5
    }
  },
  '@media screen and (min-width: 24rem)': {
    button: {
      fontSize: '1.35em'
    },
    cardActionArea: {
      width: 185,
      height: 185
    }
  },
  '@media screen and (min-width: 30rem)': {
    button: {
      fontSize: '2em'
    },
    cardActionArea: {
      width: 250,
      height: 250
    }
  }
});

export class WordDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = { sound: null };
  }

  formatAndHighlight = (text, higlight, style) => {
    // Split on higlight term and include term into parts, ignore case
    const newStr = text.charAt(0).toUpperCase() + text.slice(1);
    const parts = newStr.split(new RegExp(`(${higlight})`, 'gi'));
    let done = false;

    const outputWord = (
      <span>
        {parts.map((part, i) => {
          const val = (
            <span
              key={i}
              className={
                part.toLowerCase() === higlight.toLowerCase() && !done
                  ? style
                  : {}
              }
            >
              {part}
            </span>
          );

          if (part.toLowerCase() === higlight.toLowerCase()) {
            done = true;
          }
          return val;
        })}
      </span>
    );

    return outputWord;
  };

  format = text => {
    return text;
  };

  handlePlayAudio = () => {
    const { word } = this.props;
    const { sound } = this.state;

    if (word.audio) {
      if (sound != null) {
        sound.stop();
        sound.unload();
        this.setState(() => ({ sound: null }));
      }
      const newSound = new Howl({
        src: [word.audio]
      });
      newSound.play();
      this.setState(() => ({
        sound: newSound
      }));
    }
  };

  render() {
    const { classes, word, highlight } = this.props;

    return (
      <Grid container spacing={1} className={classes.body}>
        {word && (
          <div>
            <Grid item xs={12}>
              {word.image && (
                <Card className={classes.card}>
                  <CardActionArea className={classes.cardActionArea}>
                    <CardMedia
                      component="img"
                      className={classes.media}
                      image={word.image}
                      onClick={this.handlePlayAudio}
                    />
                  </CardActionArea>
                </Card>
              )}
            </Grid>
            <Grid item xs={12}>
              <div
                className={classes.translationWord}
                onKeyPress={this.handlePlayAudio}
                onClick={this.handlePlayAudio}
                role="button"
                tabIndex={0}
              >
                {highlight
                  ? this.formatAndHighlight(
                      word.translationLit,
                      highlight,
                      classes.hightLight
                    )
                  : this.format(word.translationLit)}
                {word.audio && (
                  <IconButton
                    color="primary"
                    className={classes.button}
                    aria-label="play the sound"
                  >
                    <VolumeUp className={classes.icon} />
                  </IconButton>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.englishWord}>
                {!!word.english && this.format(`"${word.english}"`)}
              </div>
            </Grid>
          </div>
        )}
        {!word && <p>WORD NOT SET</p>}
      </Grid>
    );
  }
}

WordDisplay.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WordDisplay);
