// words REDUCER
// const bodyPartsWordReducerDefaultState = [
//   's8kgUISN8RdXGE6gRJnn',
//   'Wf1NfZyRP7RI1NBmjf9G',
//   'sdTgomW3tgNZYIPlu95V',
//   'PzRzeSjX0Xo23kOcPi9Z',
//   'Eb2w2NPMb5szJ5MKzvrE',
//   'uQSy0QFOrrpXrsEOCix5',
//   'EtcDGITFus9dJWjQbWDg',
//   'KAHo2c70KNhrAazyvNfQ',
//   'VkUd0XKE7GayJHQpowNT',
//   'tP7Lqr0y3WG6VFsoJhEI',
//   'wrsCGllDdNy146PiotPT',
//   'MI1rPZAWVmM1xLjxVdRa',
//   'RQ0PCv8FSc0Lf4KUaaaG',
//   'xobB9qK78PIcdtR1LkZq',
//   'kMgAsMXgjVsawWtS6rZH',
//   'IAjV7RNrF8hpIfEM5SQm',
//   'j01ASfN2AUkIPu6JeqN3'
// ];
const bodyPartsWordReducerDefaultState = [];

export default (state = bodyPartsWordReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_BODY_IDS':
      return action.ids;
    default:
      return state;
  }
};
