import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import PageHead from '../components/PageHead';
import { startSetIntonationIds } from '../actions/intonationWordActions';

const styles = theme => ({
  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '1em'
  },
  header: {
    textAlign: 'center'
  },
  table: {
    margin: '0 auto',
    border: '2px'
  },
  headerTitle: {
    color: '#929292',
    fontStyle: 'italic'
  },
  headerTranslation: {
    color: '#929292',
    fontStyle: 'italic',
    fontSize: '1.3em'
  },
  headerEx: {
    textAlign: 'center'
  },
  headerIcon: {
    verticalAlign: 'middle'
  },
  text: {
    margin: '0px 10px 10px 10px',
    alignItems: 'centers'
  },
  highlight1: {
    fontWeight: 'bold',
    color: red[500]
  },
  highlight2: {
    fontWeight: 'bold',
    color: blue[500]
  },
  highlight: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark
  },
  quoteText: {
    textIndent: '20px'
  },
  '@media screen and (min-width: 25rem)': {
    body: {
      marginLeft: 10,
      marginRight: 10
    },
    text: {
      fontSize: '1.2em'
    },
    quoteText: {
      fontSize: '0.8em'
    },
    headerEx: {
      fontSize: '1.4em'
    },
    headerEx2: {
      fontSize: '1.2em'
    },
    table: {
      borderSpacing: '10px 3px'
    }
  },
  '@media screen and (min-width: 45rem)': {
    body: {
      marginLeft: 110,
      marginRight: 110
    },
    text: {
      fontSize: '1.6em'
    },
    quoteText: {
      fontSize: '0.7em'
    },
    headerTranslation: {
      fontSize: '1.2em'
    },
    headerEx: {
      fontSize: '2em'
    },
    headerEx2: {
      fontSize: '2em'
    },
    table: {
      borderSpacing: '25px 3px'
    }
  }
});

class ElisionPage extends React.Component {
  componentDidMount() {
    const { startSetIntonationIdsAction } = this.props;
    startSetIntonationIdsAction();
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <PageHead
          title="Esan Names and Meanings"
          description="Common Esan Names and Their Meanings"
          keywords="esan,words,esan words,ishan,edo,nigeria,learn,elision,deletion,vowel elision,language,name,translation"
        />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h1 className={classes.header}>Esan Names and Meanings</h1>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.text}>
              <table className={classes.table}>
                <tbody>
                  <tr>
                    <td>
                      <strong>Ainose</strong>
                    </td>
                    <td>
                      <p>No one is greater than God</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Akhator</strong>
                    </td>
                    <td>
                      <p>I will last long</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Akhere</strong>
                    </td>
                    <td>
                      <p>The younger twin</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Ebhiremen</strong>
                    </td>
                    <td>
                      <p>What destiny gave to me</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Eghonghon</strong>
                    </td>
                    <td>
                      <p>Joy</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Ehi</strong>
                    </td>
                    <td>
                      <p>Destiny</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Ehijie</strong>
                    </td>
                    <td>
                      <p>Destiny sent</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Ehimen</strong>
                    </td>
                    <td>
                      <p>Good Luck / My gaurdian angel is good</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Ehizele</strong>
                    </td>
                    <td>
                      <p>Because of destiny</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Ejemen</strong>
                    </td>
                    <td>
                      <p>A good place</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Eromosele</strong>
                    </td>
                    <td>
                      <p>Prayer has been answered</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Esele</strong>
                    </td>
                    <td>
                      <p>Gift</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Eseose</strong>
                    </td>
                    <td>
                      <p>Grace/ God&rsquo;s Grace</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Ighodalo</strong>
                    </td>
                    <td>
                      <p>Looking forward/ I am looking forward</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Isimenmen</strong>
                    </td>
                    <td>
                      <p>I prosper in a foreign land</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Itohan</strong>
                    </td>
                    <td>
                      <p>Mercy</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Ivie</strong>
                    </td>
                    <td>
                      <p>Jewel, Precious</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Obehi</strong>
                    </td>
                    <td>
                      <p>In Gods hands</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Odehi</strong>
                    </td>
                    <td>
                      <p>Road to destiny</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Odianosen</strong>
                    </td>
                    <td>
                      <p>It is well / All is well</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Odion</strong>
                    </td>
                    <td>
                      <p>The elder twin</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Oduwa</strong>
                    </td>
                    <td>
                      <p>Road to wealth / Road home</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Ofure</strong>
                    </td>
                    <td>
                      <p>Peace / It is well</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Ojiemen</strong>
                    </td>
                    <td>
                      <p>My King</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Okalo</strong>
                    </td>
                    <td>
                      <p>First person</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Olumenese</strong>
                    </td>
                    <td>
                      <p>He does me right</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Omodion</strong>
                    </td>
                    <td>
                      <p>Senior Child</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Omoehi</strong>
                    </td>
                    <td>
                      <p>Child of Destiny</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Omoehi</strong>
                    </td>
                    <td>
                      <p>Child of destiny</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Omon</strong>
                    </td>
                    <td>
                      <p>Child</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Omonigho</strong>
                    </td>
                    <td>
                      <p>A child is more important than wealth</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Omoyemen</strong>
                    </td>
                    <td>
                      <p>My child is precious / Valuable</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Omozele</strong>
                    </td>
                    <td>
                      <p>Because of child</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Onose</strong>
                    </td>
                    <td>
                      <p>Child of God</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Osebor</strong>
                    </td>
                    <td>
                      <p>God Builds</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Osedebamen</strong>
                    </td>
                    <td>
                      <p>God is with me</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Oseghale</strong>
                    </td>
                    <td>
                      <p>God plans, God shares</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Oseghe</strong>
                    </td>
                    <td>
                      <p>Looking at God</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Osejade</strong>
                    </td>
                    <td>
                      <p>God will not let me fall</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Osejie</strong>
                    </td>
                    <td>
                      <p>God sent</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Osemegbe</strong>
                    </td>
                    <td>
                      <p>God is too good</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Osemekhian</strong>
                    </td>
                    <td>
                      <p>I am going on my own</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Osemudiamen</strong>
                    </td>
                    <td>
                      <p>God stands with me</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Osemundianmen</strong>
                    </td>
                    <td>
                      <p>God is standing with me</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Oseojie</strong>
                    </td>
                    <td>
                      <p>God is the giver</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Oseremen</strong>
                    </td>
                    <td>
                      <p>God gives me</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Oseronamen</strong>
                    </td>
                    <td>
                      <p>God gave this to me</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Osezele</strong>
                    </td>
                    <td>
                      <p>Because of God</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Osezua</strong>
                    </td>
                    <td>
                      <p>It is God that gives wealth</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Osose</strong>
                    </td>
                    <td>
                      <p>Gods own / For God</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Oziegbe/ Iziegbe</strong>
                    </td>
                    <td>
                      <p>Patience</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ElisionPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const vals = [];
  state.intonationIds.forEach(id => {
    vals.push(state.words.filter(word => word.id === id).pop());
  });
  return {
    words: vals,
    wordIds: state.intonationIds
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startSetIntonationIdsAction: () => dispatch(startSetIntonationIds())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ElisionPage));
