// words REDUCER
// const animalsWordReducerDefaultState = [
//   'KpxOBOW6RrJecNew5I7v',
//   '7JB926DCG6BMyhrk7vvl',
//   '5lnXogSn8TzKa3BmPOMf',
//   'TNYrHVAjUpayp6hU8FMV',
//   'vPoWdZi2JZsRCvQORwDL',
//   'GMhxZBD9otjCbfBwh2Va',
//   'p5yCh0XfmsEACkz2GGnM',
//   'ayvKeumkfO2r7FYh6yM6',
//   'QUUUpGtAALnc1cXyGg5g',
//   'OcNJnGXfWMfXtYeTeGI6',
//   '7KzuMWnrTnNr2012Wrkk',
//   '1LaeCSNfV4mceZDm7XFn',
//   'HrCDxM4HGTZz9xKb5fZW',
//   '4rZSTZ9ivJYw9E7CrD50',
//   'LWwwUQdk3zBWWQ3GIdJQ',
//   'NUI9Zc8ZEfir298cRmMF',
//   'pKAzXKbRBZxQxVY3ZXeJ'
// ];

const animalsWordReducerDefaultState = [];
export default (state = animalsWordReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_ANIMAL_IDS':
      return action.ids;
    default:
      return state;
  }
};
