import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import PageHead from '../components/PageHead';
import { startSetIntonationIds } from '../actions/intonationWordActions';

const styles = theme => ({
  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '1em'
  },
  header: {
    textAlign: 'center'
  },
  table: {
    margin: '0 auto'
  },
  headerTitle: {
    color: '#929292',
    fontStyle: 'italic'
  },
  headerTranslation: {
    color: '#929292',
    fontStyle: 'italic',
    fontSize: '1.3em'
  },
  headerEx: {
    textAlign: 'center'
  },
  headerIcon: {
    verticalAlign: 'middle'
  },
  text: {
    margin: '0px 10px 10px 10px'
  },
  highlight1: {
    fontWeight: 'bold',
    color: red[500]
  },
  highlight2: {
    fontWeight: 'bold',
    color: blue[500]
  },
  highlight: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark
  },
  quoteText: {
    textIndent: '20px'
  },
  tableL: {
    whiteSpace: 'nowrap',
    padding: '10px'
  },
  tableR: {
    padding: '5px'
  },
  '@media screen and (min-width: 25rem)': {
    body: {
      marginLeft: 10,
      marginRight: 10
    },
    text: {
      fontSize: '1.2em'
    },
    quoteText: {
      fontSize: '0.8em'
    },
    headerEx: {
      fontSize: '1.4em'
    },
    headerEx2: {
      fontSize: '1.2em'
    },
    table: {
      borderSpacing: '10px 3px'
    }
  },
  '@media screen and (min-width: 45rem)': {
    body: {
      marginLeft: 110,
      marginRight: 110
    },
    text: {
      fontSize: '1.6em'
    },
    quoteText: {
      fontSize: '0.7em'
    },
    headerTranslation: {
      fontSize: '1.2em'
    },
    headerEx: {
      fontSize: '2em'
    },
    headerEx2: {
      fontSize: '2em'
    },
    table: {
      borderSpacing: '25px 3px'
    }
  }
});

class ElisionPage extends React.Component {
  componentDidMount() {
    const { startSetIntonationIdsAction } = this.props;
    startSetIntonationIdsAction();
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <PageHead
          title="Vowel Elision"
          description="The Importance of Vowel Elision In Esan"
          keywords="esan,words,esan words,ishan,edo,nigeria,learn,elision,deletion,vowel elision,language"
        />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h1 className={classes.header}> Vowel Elision </h1>{' '}
          </Grid>{' '}
          <Grid item xs={12}>
            <table className={classes.table}>
              <tbody>
                <tr className={classes.headerTitle}>
                  <th>Written</th>
                  <th />
                  <th>Spoken</th>
                </tr>
                <tr className={classes.headerEx}>
                  <td>
                    "Mẹn d<span className={classes.highlight1}>ẹ</span>{' '}
                    <span className={classes.highlight2}> a</span>mẹn"
                  </td>
                  <td>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </td>
                  <td>
                    "Mẹn d<span className={classes.highlight2}>a</span>mẹn"{' '}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={classes.table}>
              <tbody>
                <tr className={classes.headerTranslation}>
                  <td />
                  <td>
                    <em> "I bought water" </em>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.text}>
              <span className={classes.highlight}> A</span>n important aspect of
              learning to speak Esan is <strong> vowel elision </strong>, also
              known as <strong> vowel deletion </strong> or just{' '}
              <strong> elision</strong>. Vowel Elision is{' '}
              <strong>
                the removal of a vowel sound when two words have two vowel
                sounds next to each other.{' '}
              </strong>{' '}
              <p />
              <span className={classes.highlight}> A</span>n example of vowel
              elision in Esan can be found with the phrase{' '}
              <strong>"Mẹn dẹ amẹn"</strong> which means{' '}
              <em>"I bought water"</em>. When speaking this would be elided to{' '}
              <strong> "Mẹn damẹn" </strong>
              .The letter "ẹ" in dẹ(which means to buy) and the letter "a" in
              amẹn(water) are elided together. <p />
              Elisions only happen when speaking Esan, not when writting. <p />
            </div>
          </Grid>
          <Grid item xs={12}>
            <h2 className={classes.header}> Elision Examples </h2>
            {/** Example 1 */}
            <table className={classes.table}>
              <tbody>
                <tr className={classes.headerTitle}>
                  <th>Written</th>
                  <th />
                  <th>Spoken</th>
                </tr>
                <tr className={classes.headerEx}>
                  <td>
                    "Mẹn d<span className={classes.highlight1}>ẹ</span>{' '}
                    <span className={classes.highlight2}> a</span>mẹn"
                  </td>
                  <td>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </td>
                  <td>
                    "Mẹn d<span className={classes.highlight2}>a</span>mẹn"
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={classes.table}>
              <tbody>
                <tr className={classes.headerTranslation}>
                  <td />
                  <td>
                    <em>"I bought water"</em>
                  </td>
                </tr>
              </tbody>
            </table>
            {/** Example 2 */}{' '}
            <table className={classes.table}>
              <tbody>
                <tr className={classes.headerEx}>
                  <td>
                    "J<span className={classes.highlight1}>e</span>{' '}
                    <span className={classes.highlight2}>u</span>wẹ khian?"
                  </td>
                  <td>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </td>
                  <td>
                    "J<span className={classes.highlight2}>u</span>we khian?"
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={classes.table}>
              <tbody>
                <tr className={classes.headerTranslation}>
                  <td />
                  <td>
                    <em>"Where are you going?"</em>
                  </td>
                </tr>
              </tbody>
            </table>
            {/** Example 3 */}
            <table className={classes.table}>
              <tbody>
                <tr className={classes.headerEx2}>
                  <td>
                    "Mẹn khi<span className={classes.highlight1}>an</span>{' '}
                    <span className={classes.highlight2}>i</span>wenna"{' '}
                  </td>
                  <td>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </td>
                  <td>
                    "Mẹn kh<span className={classes.highlight2}>i</span>wenna"
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={classes.table}>
              <tbody>
                <tr className={classes.headerTranslation}>
                  <td />
                  <td>
                    <em>"I am going to work"</em>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ElisionPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const vals = [];
  state.intonationIds.forEach(id => {
    vals.push(state.words.filter(word => word.id === id).pop());
  });
  return {
    words: vals,
    wordIds: state.intonationIds
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startSetIntonationIdsAction: () => dispatch(startSetIntonationIds())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ElisionPage));
