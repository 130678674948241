import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import purple from '@material-ui/core/colors/purple';

const styles = theme => ({
  card: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  header: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center'
  },
  avatar: {
    margin: 0,
    width: 50,
    height: 50
  },
  title: {
    fontSize: '1.35em',
    marginLeft: '10px'
  },
  pos: {
    marginBottom: 12
  },
  colorRed: {
    backgroundColor: red[500]
  },
  colorBlue: {
    backgroundColor: blue[500]
  },
  colorPurple: {
    backgroundColor: purple[500]
  },
  colorGreen: {
    backgroundColor: green[500]
  },
  colorBlack: {
    backgroundColor: 'black'
  },
  colorMain: {
    backgroundColor: theme.palette.primary.main
  }
});

function DashboardWidget(props) {
  const { classes, title, children, icon, iconColor } = props;

  let colorClass = '';
  switch (iconColor) {
    case 'blue':
      colorClass = classes.colorBlue;
      break;
    case 'red':
      colorClass = classes.colorRed;
      break;
    case 'green':
      colorClass = classes.colorGreen;
      break;
    case 'purple':
      colorClass = classes.colorPurple;
      break;
    case 'black':
      colorClass = classes.colorBlack;
      break;
    default:
      colorClass = classes.colorMain;
      break;
  }

  return (
    <Card className={classes.card}>
      <div className={classes.header}>
        {!!icon && (
          <Avatar className={`${classes.avatar} ${colorClass}`}>{icon}</Avatar>
        )}

        <div className={classes.title}>{title}</div>
      </div>
      <CardContent>
        <Typography variant="h4">{children}</Typography>
      </CardContent>
    </Card>
  );
}

DashboardWidget.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DashboardWidget);
