import React from 'react';
import ReactDOM from 'react-dom';
import './styles/styles.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

require('typeface-roboto');
require('typeface-baloo-chettan');
require('typeface-kaushan-script');
// Wait for the stores to initialize then render page

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
