import { storage } from './firebase';

export const fileUploadHelper = (word, file, fileType, bucketPath) => {
  if (file !== '' && typeof file === 'object') {
    console.log('Uploading ', file.name, ' to ', bucketPath);
    const fileRootRef = storage.ref(bucketPath);
    const name = fileType === 'image' ? word.imageName : word.audioName;
    const fileRef = fileRootRef.child(name);

    return fileRef
      .put(file)
      .then(snap => {
        return snap.ref.getDownloadURL().then(url => {
          console.log('updating attributes ');

          return fileType === 'image'
            ? Promise.resolve({ ...word, image: url })
            : Promise.resolve({ ...word, audio: url });
        });
      })
      .catch(error => {
        console.log('Error saving file: ', error);
      });
  }
  console.log('No update needed for ', fileType);
  return fileType === 'image'
    ? Promise.resolve({ ...word, image: '', imageName: '' })
    : Promise.resolve({ ...word, audio: '', audioName: '' });
};

export const fileDeleter = (oldWord, newWord, fileType) => {
  const ogFile = fileType === 'image' ? oldWord.imageName : oldWord.audioName;
  const newFile = fileType === 'image' ? newWord.imageName : newWord.audioName;
  const newFilePath = fileType === 'image' ? newWord.image : newWord.audio;

  const bucketPath =
    fileType === 'image'
      ? process.env.REACT_APP_CDN_BASE_IMAGE_DIR
      : process.env.REACT_APP_CDN_BASE_AUDIO_DIR;

  console.log(`${fileType} Comparing ${ogFile} to ${newFile}`);
  console.log(typeof newFilePath);
  if (ogFile !== newFile || typeof newFilePath === 'object') {
    if (ogFile !== '') {
      console.log('Deleting ', ogFile, ' from ', bucketPath);
      storage
        .ref(bucketPath)
        .child(ogFile)
        .delete()
        .then(() => {
          console.log('file deleted');
        })
        .catch(error => {
          console.log('Error Deleting file: ', error);
        });
    }
    return fileType === 'image'
      ? fileUploadHelper(newWord, newWord.image, 'image', bucketPath)
      : fileUploadHelper(newWord, newWord.audio, 'audio', bucketPath);
  }
  console.log('Not updating ', fileType);
  return Promise.resolve(newWord);
};
