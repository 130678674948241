import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/firebase-storage';

// Initialize Firebase
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
};

firebase.initializeApp(config);
const db = firebase.firestore();
const storage = firebase.storage();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

// UI Config for Sign Ins

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/dashboard',
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase.auth.FacebookAuthProvider.PROVIDER_ID]

  // callbacks: {
  //   // Avoid redirects after sign-in.
  //   signInSuccessWithAuthResult: (authResult, redirectUrl) => {
  //     // return in true sends the redirect
  //   }
  // }
};

export { firebase, storage, facebookAuthProvider, uiConfig, db as default };
