import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  card: {
    margin: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '50%',
    background: theme.palette.primary.main,
    minWidth: 102,
    minHeight: 102
  },
  title: {
    textAlign: 'center',
    fontSize: '1.3em',
    fontWeight: 'bold',
    textDecoration: 'none',
    color: 'black'
  },
  icon: {
    fontSize: '4.5em'
  },
  iconAndTitle: {
    paddingBottom: 0,
    textAlign: 'center',
    textDecoration: 'none',
    color: 'black'
  },
  link: {
    textDecoration: 'none'
  },
  '@media screen and (min-width: 25rem)': {
    title: {
      fontSize: '1.1em'
    }
  },
  '@media screen and (min-width: 45rem)': {
    title: {
      fontSize: '1.3em'
    }
  }
});

export function SectionCard(props) {
  const { classes, children, pagePath, title } = props;
  const nChildren = React.Children.map(children, child => {
    return React.cloneElement(child, {
      className: classes.icon
    });
  });

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Link to={pagePath}>
          <div className={classes.iconAndTitle}>{nChildren}</div>
        </Link>
      </Card>
      <Link to={pagePath} className={classes.link}>
        <Typography component="h1" className={classes.title}>
          {title !== undefined ? title : 'Section Title'}
        </Typography>
      </Link>
    </div>
  );
}

SectionCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SectionCard);
