import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';
import HomePage from '../pages/HomePage';
import Header from '../components/Header';
import AppPages from './AppPages';
import DashboardLayout from '../layout/DashboardLayout';
import PrivateRoute from './PrivateRoute';

export const history = createBrowserHistory();

const styles = theme => ({
  root: {
    maxWidth: '1240px'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  toolbar: theme.mixins.toolbar
});

const AppRouter = ({ classes }) => (
  <Router history={history}>
    <div>
      <Header history={history} />
      <Switch>
        <Route path="/" component={HomePage} exact />
        <PrivateRoute path="/dashboard" component={DashboardLayout} />
        <Route component={AppPages} />
      </Switch>
    </div>
  </Router>
);

export default withStyles(styles)(AppRouter);
