import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGraduationCap,
  faLaptop,
  faMoneyBillAlt
} from '@fortawesome/free-solid-svg-icons';
import background from '../images/header-bg-min.jpg';

const styles = theme => ({
  header: {
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    minHeight: '99vh'
  },
  homeBanner: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    paddingBottom: '64px'
  },
  homeTitle: {
    textIndent: '.7rem',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    letterSpacing: '.7rem',
    marginBottom: '0.35em',
    textAlign: 'center',
    fontSize: '1.4rem',
    lineHeight: '1.13333em',
    marginLeft: '-.02em',
    marginBlockStart: '0'
  },
  homeSubtitle: {
    maxWidth: '400px',
    fontWeight: '400',
    margin: '0',
    display: 'block'
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  bodySection: {
    textAlign: 'center',
    minHeight: '300px'
  },
  footer: {
    display: 'flex',
    textAlign: 'center',
    verticalAlign: 'middle'
  },
  button: {
    width: '250px',
    height: '50px',
    borderRadius: '20px',
    margin: theme.spacing(1),
    fontSize: '1em'
  },
  '@media screen and (min-width: 15rem)': {
    homeTitle: {
      fontSize: '1.35rem'
    },
    homeSubtitle: {
      fontSize: '1.2rem'
    },
    divider: {
      height: '5px'
    }
  },
  '@media screen and (min-width: 60rem)': {
    homeTitle: {
      fontSize: '2.8rem'
    },
    homeSubtitle: {
      fontSize: '1.5rem'
    },
    divider: {
      height: '3px'
    }
  }
});

const PageHead = () => {
  return (
    <Helmet>
      <title> Learn Esan Online. For Free </title>
      <meta
        name="description"
        content="Learn the Esan language with our collection of free online tutorials, audio, notes, grammar, vocabulary, and quizzes. LearningEsan.com is the perfect resource for learning the Esan language of Nigeria."
      />
      <meta
        name="keywords"
        content="home,education,language,esan,words,esan words,ishan,edo,nigeria,learn"
      />
    </Helmet>
  );
};

export const HomePage = props => {
  const { classes } = props;
  return (
    <div>
      {PageHead()}
      <Grid container>
        <Grid item xs={12}>
          <Grid className={classes.header}>
            <div className={classes.homeBanner}>
              <h1 className={classes.homeTitle}> LEARNING ESAN </h1>{' '}
              <h1 className={classes.homeSubtitle}> Discover the language </h1>{' '}
              <Link
                to="/learn"
                style={{
                  textDecoration: 'none'
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Get Started
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.body}>
          <Grid item xs={8} className={classes.bodySection}>
            <h2>The best way to learn Esan.</h2>
            <FontAwesomeIcon icon={faGraduationCap} size="9x" />
            <h3>
              LearningEsan.com is the easiest way to learn the Esan language.
              Our learning sections provide a detailed look into the
              fundamentals so you can quickly start speaking.
            </h3>
            <Divider variant="middle" className={classes.divider} />
          </Grid>
          <Grid item xs={8} className={classes.bodySection}>
            <h2>Rich content and interactive material.</h2>
            <FontAwesomeIcon icon={faLaptop} size="9x" color="Purple" />
            <h3>
              Soon we will have audio examples, interactice sections, and
              detailed grammar rules to help you increase your fluency.
            </h3>
            <Divider variant="middle" className={classes.divider} />
          </Grid>
          <Grid item xs={8} className={classes.bodySection}>
            <h2>Boost your understanding and dive deeper. For free.</h2>
            <FontAwesomeIcon icon={faMoneyBillAlt} size="9x" color="Green" />
            <h3>
              Our goal is to help everyone learn the language. That's why
              LearningEsan will always be free of charge. Forever
            </h3>
            <Divider variant="middle" className={classes.divider} />
          </Grid>
        </Grid>
        <Grid container className={classes.footer}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Link
              to="/learn"
              style={{
                textDecoration: 'none',
                fontWeight: 'bold',
                color: 'black'
              }}
            >
              Learn
            </Link>
            {' | '}
            <Link
              to="/dictionary"
              style={{
                textDecoration: 'none',
                fontWeight: 'bold',
                color: 'black'
              }}
            >
              Dictionary{' '}
            </Link>
            {' | '}
            <Link
              to="/about"
              style={{
                textDecoration: 'none',
                fontWeight: 'bold',
                color: 'black'
              }}
            >
              About Us
            </Link>
            {' | '}{' '}
            <Link
              to="/contact"
              style={{
                textDecoration: 'none',
                fontWeight: 'bold',
                color: 'black'
              }}
            >
              Contact{' '}
            </Link>{' '}
            {' | '}{' '}
            <a
              href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=W2PD6GD8GBRRU"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.menuItem}
              style={{
                textDecoration: 'none',
                fontWeight: 'bold',
                color: 'black'
              }}
            >
              Donate{' '}
            </a>{' '}
          </Grid>{' '}
          <Grid item xs={1} />{' '}
        </Grid>
      </Grid>
    </div>
  );
};

HomePage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(HomePage);
