import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import HomeIcon from '@material-ui/icons/Home';
import PetsIcon from '@material-ui/icons/Pets';
import Grid from '@material-ui/core/Grid';
import PageHead from '../components/PageHead';
import SectionCard from '../components/SectionCard';

const styles = {
  body: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap'
  },
  title: {
    fontFamily: '"Baloo Chettan", cursive',
    letterSpacing: '.06em',
    textAlign: 'center'
  },
  icon: {
    fontSize: 80,
    textDecoration: 'none',
    color: 'black'
  }
};

const VocabularyPage = props => {
  const { classes } = props;
  return (
    <div>
      <PageHead
        title="Common Vocabulary"
        description="Learn Some Common Words In Esan"
        keywords="esan,ishan,edo,nigeria,learn,vocabulary,words,language"
      />
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <div className={classes.title}>
            <h1>Common Vocabulary</h1>
          </div>
        </Grid>
        <Grid item xs={6} sm={4}>
          <SectionCard
            title="Parts of The Body"
            pagePath="/learn/words/body-parts"
          >
            <AccessibilityNewIcon className={classes.icon} />
          </SectionCard>
        </Grid>
        <Grid item xs={6} sm={4}>
          <SectionCard
            title="Items Around The House"
            pagePath="/learn/words/household-items"
          >
            <HomeIcon className={classes.icon} />
          </SectionCard>
        </Grid>
        <Grid item xs={6} sm={4}>
          <SectionCard title="Animals" pagePath="/learn/words/animals">
            <PetsIcon className={classes.icon} />
          </SectionCard>
        </Grid>
      </Grid>
    </div>
  );
};

VocabularyPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(VocabularyPage);
