// Get visible words
export default (words, { text, sortBy, startDate, endDate }) => {
  return words
    .filter(word => {
      // const startDateMatch = typeof startDate !== 'number' || word.createdAt >= startDate;
      // const endDateMatch = typeof endDate !== 'number' || word.createdAt <= endDate;
      const filterStr = text.length > 0 ? text.toLowerCase() : '';

      const textMatch =
        word.translation.toLowerCase().includes(filterStr) ||
        word.english.toLowerCase().includes(filterStr);

      return textMatch;
    })
    .sort((a, b) => {
      if (sortBy === 'translation') {
        return a.translation.localeCompare(b.translation);
      }
      return a.english.localeCompare(b.english);
    });
};
