import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DualWordDisplay from '../components/Words/DualWordDisplay';
import PageHead from '../components/PageHead';
import { startSetIntonationIds } from '../actions/intonationWordActions';

const styles = theme => ({
  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '1em'
  },
  header: {
    textAlign: 'center'
  },
  text: {
    margin: '0px 10px 10px 10px'
  },
  highlight: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark
  },
  quoteText: {
    textIndent: '25px'
  },
  tableL: {
    whiteSpace: 'nowrap',
    padding: '10px'
  },
  tableR: {
    padding: '5px'
  },
  '@media screen and (min-width: 25rem)': {
    body: {
      marginLeft: 10,
      marginRight: 10
    },
    text: {
      fontSize: '1.3em'
    },
    quoteText: {
      fontSize: '0.8em'
    }
  },
  '@media screen and (min-width: 45rem)': {
    body: {
      marginLeft: 110,
      marginRight: 110
    },
    text: {
      fontSize: '1.8em'
    },
    quoteText: {
      fontSize: '0.7em'
    }
  }
});

class IntonationPage extends React.Component {
  componentDidMount() {
    const { startSetIntonationIdsAction } = this.props;
    startSetIntonationIdsAction();
  }

  render() {
    const { classes, words } = this.props;
    return (
      <div>
        <PageHead
          title="Intonation in Esan"
          description="Learn The Importance of Intonaion and Tone in Esan"
          keywords="esan,words,esan words,ishan,edo,nigeria,learn,intonation,tone,language"
        />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h1 className={classes.header}>Intonation</h1>
          </Grid>
          <Grid item xs={12}>
            {!!words && !!words[0] && (
              <div>
                <DualWordDisplay
                  wordPairs={words}
                  highlightPairs={['á', 'à', 'Á', 'À', 'É', 'È', 'Ò', 'Ó']}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <div className={classes.text}>
              <span className={classes.highlight}>E</span>san is a{' '}
              <span>
                <em>
                  <strong>tonal language</strong>
                </em>
              </span>
              . According to Wikipedia, a tonal language is:
              <p />
              <div className={classes.quoteText}>
                <em>
                  {'"'}A language in which saying words with different "tones"
                  (like pitches in music) change the meaning of the words, even
                  if the pronunciation of the word is the same otherwise.{'"'}
                </em>
              </div>
              <p />
              <span className={classes.highlight}>T</span>his means a different
              pitch/tone can convey different word <strong>meaning</strong> or{' '}
              <strong>grammatical distinction</strong>. All tones are expressed
              with <strong>Tone Marks</strong>. These marks are applied to the
              top of each syllable of a word or phrase.
              <p />
              There are{' '}
              <span>
                <strong>three types of tone marks:</strong>
              </span>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.tableL}>
                      <span>
                        <strong>
                          <em>Low (à) </em>
                        </strong>
                      </span>
                    </td>
                    <td className={classes.tableR}>
                      Low with a falling tone, depicted by a grave accent{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tableL}>
                      <span>
                        <strong>
                          <em>Mid (a) </em>
                        </strong>
                      </span>
                    </td>
                    <td className={classes.tableR}>
                      Mid with a flat tone, depicted by an absence of any accent
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tableL}>
                      <span>
                        <strong>
                          <em>High (á) </em>
                        </strong>
                      </span>
                    </td>
                    <td className={classes.tableR}>
                      High with a rising tone, depicted by an acute accent
                    </td>
                  </tr>
                </tbody>
              </table>
              <p />
              <span className={classes.highlight}>C</span>orrectly pronouncing
              and hearing tones in Esan is crucial to learning the language
              since meaning can vary widely between words and phrases based on
              tone. For native speakers of non-tonal languages (like English)
              tone differentiation can be challenging at first since subtle
              differences in tone are usually ignored in most English words or
              phrases.
              <p />
              <p />
              <p />
              <p />
              <p />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

IntonationPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const vals = [];
  state.intonationIds.forEach(id => {
    vals.push(state.words.filter(word => word.id === id).pop());
  });
  return {
    words: vals,
    wordIds: state.intonationIds
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startSetIntonationIdsAction: () => dispatch(startSetIntonationIds())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(IntonationPage));
