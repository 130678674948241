import db from '../firebase/firebase';

export const setCountingIds = ids => ({
  type: 'SET_COUNTING_IDS',
  ids
});

export const startSetCountingIds = () => {
  return dispatch => {
    return db
      .collection('esan-pages')
      .doc('counting-page')
      .get()
      .then(docRef => {
        const obj = docRef.data();
        const output = [];

        Object.getOwnPropertyNames(obj).forEach(item => {
          output.push(obj[item]);
        });
        dispatch(setCountingIds(output));
        return Promise.resolve(output);
      });
  };
};
