import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const styles = {
  button: {
    padding: '6px 12px 6px 12px',
    border: 1,
    margin: 2,
    cursor: 'pointer',
    fontWeight: 'bold',
    color: 'black'
  },
  '@media screen and (min-width: 20rem)': {
    button: {
      fontSize: '1.3em'
    }
  },
  '@media screen and (min-width: 30rem)': {
    button: {
      fontSize: '2em'
    }
  }
};

export class WordDisplayButton extends React.Component {
  handleButtonClicked = () => {
    const { onButtonClicked, wordId, highlight } = this.props;
    onButtonClicked(wordId, highlight);
  };

  render() {
    const { classes, children } = this.props;
    return (
      <IconButton
        color="primary"
        className={classes.button}
        onClick={this.handleButtonClicked}
      >
        {children}
      </IconButton>
    );
  }
}

WordDisplayButton.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WordDisplayButton);
