/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { CardContent } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VolumeUp from '@material-ui/icons/VolumeUpRounded';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { Howl } from 'howler';
import Dialog from '../../modal/Dialog';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none'
  },
  card: {
    marginBottom: '8px'
  },
  form: {
    display: 'block'
  },
  leftIcon: {
    marginRight: theme.spacing(1),
    fontSize: 20
  },
  iconSmall: {
    fontSize: 20
  },
  input: {
    display: 'none'
  },
  footer: {
    marginTop: '15px'
  },
  uploadSection: {
    marginBottom: '10px'
  },
  '@media screen and (min-width: 15rem)': {
    media: {
      objectFit: 'cover',
      width: '130px',
      height: '130px'
    }
  },
  '@media screen and (min-width: 24rem)': {
    media: {
      objectFit: 'cover',
      width: '180px',
      height: '190px'
    }
  },
  '@media screen and (min-width: 30rem)': {
    media: {
      objectFit: 'cover',
      maxWidth: '240px',
      maxHheight: '240px'
    }
  }
});

export class WordForm extends React.Component {
  constructor(props) {
    super(props);
    const { word } = props;
    const { translation = '', translationLit = '', english = '' } = word;

    this.state = {
      word,
      sound: null,
      storedImage: true,
      storedAudio: true,
      transEr: this.checkTransEr(translation),
      transLitEr: this.checkTransLitEr(translationLit),
      englishEr: this.checkEnglishEr(english),
      success: false,
      buttonDisabled: false,
      openModal: false
    };
  }

  handleImageSelected = e => {
    if (e.target.files && e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(prevState => ({
        ...prevState,
        word: {
          ...prevState.word,
          image,
          imageName: image.name
        },
        storedImage: false
      }));
      e.target.value = null;
    }
  };

  handleClearImage = () => {
    this.setState(prevState => ({
      ...prevState,
      word: {
        ...prevState.word,
        image: '',
        imageName: ''
      }
    }));
  };

  handleAudioSelected = e => {
    if (e.target.files && e.target.files[0]) {
      const audio = e.target.files[0];
      this.setState(prevState => ({
        ...prevState,
        word: {
          ...prevState.word,
          audio,
          audioName: audio.name
        },
        storedAudio: false
      }));
      e.target.value = null;
    }
  };

  handleClearAudio = () => {
    this.setState(prevState => ({
      ...prevState,
      word: {
        ...prevState.word,
        audio: '',
        audioName: ''
      }
    }));
  };

  handlePlayAudio = () => {
    const { word, sound, storedAudio } = this.state;

    if (word.audio) {
      if (sound != null) {
        sound.stop();
        sound.unload();
        this.setState(() => ({ sound: null }));
      }
      const newSound = new Howl({
        src: [storedAudio ? word.audio : URL.createObjectURL(word.audio)],
        format: ['mp3', 'wav']
      });
      newSound.play();
      this.setState(() => ({
        sound: newSound
      }));
    }
  };

  handleValidateTrans = e => {
    const { value } = e.target;
    this.setState(prevState => ({
      ...prevState,
      word: {
        ...prevState.word,
        translation: value
      },
      transEr: this.checkTransEr(value)
    }));
  };

  handleValidateTransLit = e => {
    const { value } = e.target;

    this.setState(prevState => ({
      ...prevState,
      word: {
        ...prevState.word,
        translationLit: value
      },
      transLitEr: this.checkTransLitEr(value)
    }));
  };

  handleValidateEnglish = e => {
    const { value } = e.target;
    this.setState(prevState => ({
      ...prevState,
      word: {
        ...prevState.word,
        english: value
      },
      englishEr: this.checkEnglishEr(value)
    }));
  };

  handleSubmit = e => {
    e.preventDefault();
    const { handleWordSubmit } = this.props;
    const { word } = this.state;

    this.setState(() => ({
      buttonDisabled: true
    }));
    handleWordSubmit(word).then(resolvedWord => {
      this.setState(() => {
        const newWord = word.id ? resolvedWord : {};
        return {
          word: newWord,
          transEr: this.checkTransEr(newWord.translation),
          transLitEr: this.checkTransLitEr(newWord.translationLit),
          englishEr: this.checkEnglishEr(newWord.english),
          success: true,
          buttonDisabled: false,
          storedImage: true,
          storedAudio: true
        };
      });

      setTimeout(() => {
        this.setState(prevState => ({
          ...prevState,
          success: false
        }));
      }, 3500);
    });
  };

  handleDelete = () => {
    this.setState(() => ({
      openModal: true
    }));
  };

  checkTransEr = (value = '') => {
    return value.trim().length <= 0;
  };

  checkTransLitEr = (value = '') => {
    const { word } = this.props;
    const { words } = this.props;
    const nValue = value.toLowerCase();

    const foundWords = words.filter(
      wordItem => wordItem.translationLit.toLowerCase() === nValue
    );

    const result = word.id
      ? false || nValue.trim().length <= 0
      : foundWords.length > 0 || nValue.trim().length <= 0;
    return result;
  };

  checkEnglishEr = (value = '') => {
    return value.length <= 0;
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  handlePickOption1 = () => {};

  handlePickOption2 = () => {
    const { handleWordDelete } = this.props;
    const { word } = this.state;

    handleWordDelete(word).then(wordRef => {
      this.setState(() => ({
        word: {}
      }));
      console.log('Word has been deleted: ', wordRef);
    });
  };

  render() {
    const { classes, title } = this.props;
    const {
      word,
      transEr,
      transLitEr,
      englishEr,
      success,
      buttonDisabled,
      storedImage,
      openModal
    } = this.state;

    return (
      <Card className={classes.card}>
        <h2>{title}</h2>
        <CardContent>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <div>
              {success && (
                <div>
                  <CheckCircleIcon
                    color="primary"
                    style={{ fontSize: 30 }}
                    className={classNames(classes.leftIcon, classes.iconSmall)}
                  />{' '}
                  <h3>Saved!</h3>
                </div>
              )}
              <div>
                <div>
                  {!!word.image && (
                    <img
                      className={classes.media}
                      src={
                        storedImage
                          ? word.image
                          : URL.createObjectURL(word.image)
                      }
                      alt="word-display"
                    />
                  )}
                </div>
                <div>
                  {!!word.audio && (
                    <IconButton
                      color="primary"
                      className={classes.button}
                      onClick={this.handlePlayAudio}
                      aria-label="play the sound"
                    >
                      <VolumeUp className={classes.icon} />
                    </IconButton>
                  )}
                </div>
              </div>
              <TextField
                required
                id="translation"
                label="Translation"
                placeholder="awa"
                value={word.translation || ''}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleValidateTrans}
                error={transEr}
                helperText={transEr && "Translation can't be empty"}
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
              />
            </div>
            <div>
              <TextField
                required
                id="translationLit"
                label="Translation Literal"
                placeholder="áwà"
                value={word.translationLit || ''}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleValidateTransLit}
                error={transLitEr}
                helperText={
                  transLitEr &&
                  (word.translationLit === ''
                    ? "Translation Lit can't be empty"
                    : 'Translation Literal already exists')
                }
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
              />
            </div>
            <div>
              <TextField
                required
                id="english"
                label="English"
                placeholder="dog"
                value={word.english || ''}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                onChange={this.handleValidateEnglish}
                error={englishEr}
                helperText={englishEr && "English word can't be empty"}
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
              />
            </div>
            <div className={classes.uploadSection}>
              <label htmlFor="image">
                <input
                  accept="image/*"
                  className={classes.input}
                  id="image"
                  type="file"
                  onChange={this.handleImageSelected}
                />
                {!!word.image === false && (
                  <Button
                    size="small"
                    variant="contained"
                    component="span"
                    className={classes.button}
                  >
                    Upload Image
                  </Button>
                )}
              </label>
              {!!word.image === true && (
                <Button
                  color="secondary"
                  size="small"
                  variant="contained"
                  className={classes.button}
                  onClick={this.handleClearImage}
                >
                  Remove Image
                </Button>
              )}
            </div>
            <div className={classes.uploadSection}>
              <label htmlFor="audio">
                <input
                  accept="audio/*"
                  className={classes.input}
                  id="audio"
                  type="file"
                  onChange={this.handleAudioSelected}
                />
                {!!word.audio === false && (
                  <Button
                    size="small"
                    variant="contained"
                    component="span"
                    className={classes.button}
                  >
                    Upload Audio
                  </Button>
                )}
              </label>
              {!!word.audio === true && (
                <Button
                  color="secondary"
                  size="small"
                  variant="contained"
                  component="span"
                  className={classes.button}
                  onClick={this.handleClearAudio}
                >
                  Remove Audio
                </Button>
              )}
            </div>
            <div className={classes.footer}>
              <Button
                size="large"
                color="primary"
                variant="contained"
                type="submit"
                disabled={transEr || transLitEr || englishEr || buttonDisabled}
              >
                <SaveIcon
                  className={classNames(classes.leftIcon, classes.iconSmall)}
                />
                {word.id ? 'Update' : 'Save'}
              </Button>
              {!!word.id && (
                <Button
                  size="large"
                  color="secondary"
                  variant="contained"
                  type="button"
                  onClick={this.handleDelete}
                >
                  <Delete
                    className={classNames(classes.leftIcon, classes.iconSmall)}
                  />
                  Delete
                </Button>
              )}
            </div>
            <Dialog
              open={openModal}
              close={this.handleCloseModal}
              title="Are you sure you want to delete this word?"
              option1="Cancel"
              option2="Delete"
              option1Select={this.handlePickOption1}
              option2Select={this.handlePickOption2}
            >
              Deletion is <strong>PERMANENT</strong> and can not be reversed.
              Deleting a word also deletes all stored images and audio data
            </Dialog>
          </form>
        </CardContent>
      </Card>
    );
  }
}

WordForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    words: state.words
  };
};
export default connect(mapStateToProps)(withStyles(styles)(WordForm));
