// words REDUCER
// const intonationWordsReducerDefaultState = [
//   'xSp4p8uZFGHwB2rJ7Zao',
//   'p5yCh0XfmsEACkz2GGnM',
//   'KpxOBOW6RrJecNew5I7v',
//   'KUaS4pdanwAxs8V8g8Gb',
//   '7KzuMWnrTnNr2012Wrkk',
//   'M5EdghXjCo9NE2pBaEiZ',
//   'xobB9qK78PIcdtR1LkZq',
//   '4Bk1hxsolDVQRMHWKEYL'
// ];

const intonationWordsReducerDefaultState = [];
export default (state = intonationWordsReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_INTONATION_IDS':
      return action.ids;
    default:
      return state;
  }
};
