import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import PageHead from '../components/PageHead';
import WordListItem from '../components/WordListItem';
import selectWords from '../selectors/wordSelectors';
import { setWordsTextFilter } from '../actions/wordFilterActions';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '1em'
  },
  searchField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
    justifyContent: 'center'
  },
  resultsList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
    dense: true
  },
  wordList: {
    width: 300
  },
  '@media screen and (min-width: 25rem)': {
    body: {
      fontSize: '1em'
    },
    wordList: {
      width: 350
    }
  },
  '@media screen and (min-width: 60rem)': {
    wordList: {
      width: 550
    }
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(0, 0, 0)'
    }
  },
  typography: { useNextVariants: true }
});

class DictionaryPage extends React.Component {
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setWordsTextFilter(''));
  }

  handleFindWords = e => {
    const { dispatch } = this.props;
    const inputStr = e.target.value.toLowerCase();
    dispatch(setWordsTextFilter(inputStr));
  };

  handleKeyPress = e => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  render() {
    const title = 'Search For A Word';
    const { classes, words, wordFilters } = this.props;

    return (
      <div>
        <PageHead
          title="Esan To English Dictionary"
          description="Serach For a Word in Esan or English to Find a Translation"
          keywords="esan,words,esan words,ishan,edo,nigeria,learn,dictionary,translation,language"
        />
        <div className={classes.body}>
          <h1>{title}</h1>
          <div className={classes.searchField}>
            <MuiThemeProvider theme={theme}>
              <TextField
                id="search-field"
                label="Type in Esan or English"
                className={classes.textField}
                margin="normal"
                autoFocus
                onChange={this.handleFindWords}
                onKeyDown={this.handleKeyPress}
                type="search"
              />
            </MuiThemeProvider>
          </div>
          <div className={classes.wordList}>
            {wordFilters.length > 0 && (
              <List className={classes.resultsList}>
                {words.map(word => (
                  <WordListItem key={word.id} word={word} />
                ))}
              </List>
            )}
          </div>
        </div>
      </div>
    );
  }
}

DictionaryPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    words: selectWords(state.words, state.wordFilters),
    wordFilters: state.wordFilters.text
  };
};
export default connect(mapStateToProps)(withStyles(styles)(DictionaryPage));
