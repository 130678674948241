import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import EmailIcon from '@material-ui/icons/Email';
import CheckCircle from '@material-ui/icons/CheckCircle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PageHead from '../components/PageHead';
import DashboardWidget from '../components/DashboardWidget';
import db from '../firebase/firebase';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  margin: {
    margin: theme.spacing(1)
  },
  header: {
    textAlign: 'center'
  },
  icon: {
    color: theme.palette.primary.main
  },
  iconSent: {
    color: 'green',
    fontSize: '1em'
  },
  body: {
    fontSize: '1.35em',
    textAlign: 'center'
  },
  interactBox: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  itemBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '1.3em'
  },
  '@media screen and (max-width: 20rem)': {
    body: {
      fontSize: '1.50em'
    },
    itemBox: {
      fontSize: '1.2em'
    }
  }
});

export class ContactPage extends React.Component {
  state = {
    name: '',
    email: '',
    message: ''
  };

  handleNameChange = e => {
    const name = e.target.value;
    // console.log(this.validateEmail(name));
    this.setState(prevState => ({
      ...prevState,
      name
    }));
  };

  handleEmailChange = e => {
    const email = e.target.value;

    this.setState(prevState => ({
      ...prevState,
      email
    }));
  };

  handleMessageChange = e => {
    const message = e.target.value;

    this.setState(prevState => ({
      ...prevState,
      message
    }));
  };

  validateEmail = email => {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  handleSendEmail = () => {
    const { name, email, message } = this.state;
    const time = moment().format();
    const emailMessage = {
      name,
      email,
      message,
      date: time
    };

    db.collection('contact-form')
      .doc(`${email}-${time}`)
      .set(emailMessage)
      .then(() => {});

    this.setState(() => ({
      name: '',
      email: '',
      message: '',
      sent: true
    }));

    setTimeout(() => {
      this.setState(prevState => ({
        ...prevState,
        sent: false
      }));
    }, 3000);
  };

  render() {
    const { classes } = this.props;
    const { name, email, message, sent } = this.state;
    const nameValid = !(name !== undefined && name !== '');
    const messageValid = !(message !== undefined && message !== '');
    const emailValid = !this.validateEmail(email);

    return (
      <div>
        <PageHead
          title="Contact Us"
          description="Reach out to the Learning Esan Team"
          keywords="esan,ishan,edo,nigeria,learn,about,language,contact"
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h1 className={classes.header}>Contact Us</h1>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.body}>
              Any specific material you want to see added? Want to help
              contribute material or skills? Contact us below
              <br />
            </Typography>
          </Grid>
          <Grid item sm={3} />
          <Grid item xs={12} sm={6}>
            <DashboardWidget
              title="Contact Form"
              icon={<EmailIcon className={classes.icon} />}
              iconColor="black"
            >
              {sent && (
                <Grid item xs={12}>
                  <CheckCircle className={classes.iconSent} />
                  Sent!
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  value={name}
                  onChange={this.handleNameChange}
                  id="standard-name"
                  label="Name"
                  className={classes.textField}
                  margin="normal"
                  error={nameValid}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  value={email}
                  onChange={this.handleEmailChange}
                  id="email"
                  label="Your Email"
                  className={classes.textField}
                  margin="normal"
                  error={emailValid}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  multiline
                  fullWidth
                  value={message}
                  onChange={this.handleMessageChange}
                  id="message"
                  label="Your Message"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  error={messageValid}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={this.handleSendEmail}
                  className={classes.margin}
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={nameValid || emailValid || messageValid}
                >
                  Submit
                </Button>
              </Grid>
            </DashboardWidget>
            <Grid item sm={3} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

ContactPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ContactPage);
